/* Ticker Container */
.token-ticker-container {
    background-color: rgba(
        0,
        0,
        0,
        0.8
    ); /* Darker semi-transparent background */
    padding: 8px 20px; /* Adjusted padding for a balanced look */
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure balanced spacing */
    position: fixed; /* Fixed positioning at the top */
    top: 60px; /* Adjust this value to ensure it's not covered by other elements */
    height: 50px; /* Height of the ticker */
    left: 0; /* Ensure ticker spans full width */
    right: 0; /* Ensure ticker spans full width */
    z-index: 20; /* Make sure it's above other content */
    overflow: hidden; /* Hide overflow */
}

/* Ticker Scroll Animation */
.token-ticker-scroll {
    display: flex;
    animation: tickerScroll 40s linear infinite; /* Continuous scrolling animation with slower speed */
    white-space: nowrap;
}

/* Keyframes for ticker scrolling */
@keyframes tickerScroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

/* Individual token item */
.ticker-item {
    display: flex;
    align-items: center;
    margin: 0 12px; /* Adjusted margin to fit more items without overflow */
    gap: 10px; /* Spacing between logo and text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s, transform 0.2s; /* Smooth background transition */
}

/* Token logo */
.token-logo {
    width: 24px; /* Increased logo size for better visibility */
    height: 24px; /* Increased logo size for better visibility */
    border-radius: 50%; /* Circular logo */
    border: 1px solid #ffd700; /* Gold border for better definition */
}

/* Token symbol text */
.token-symbol {
    font-size: 16px; /* Slightly larger font size */
    color: #ffd700; /* Gold color for text */
    font-weight: 600; /* Increased weight for better visibility */
}

/* Token price text */
.token-price {
    font-size: 16px; /* Slightly larger font size */
    color: #ffd700; /* Gold color for price */
}

/* Price change (positive) */
.positive-change {
    color: #4caf50; /* Green for positive change */
    font-weight: bold; /* Bold for emphasis */
}

/* Price change (negative) */
.negative-change {
    color: #ff5722; /* Red for negative change */
    font-weight: bold; /* Bold for emphasis */
}

/* Hover effect */
.ticker-item:hover {
    background-color: rgba(
        255,
        215,
        0,
        0.15
    ); /* Slightly softer gold background on hover */
    border-radius: 6px; /* Increased border radius for a more pronounced effect */
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add subtle shadow for depth */
}
