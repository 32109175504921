.nft-owners-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    background-color: #000000; /* Black background */
    color: #f1f1f1; /* Light text for contrast */
}

.title {
    text-align: center;
    font-size: 2.5em;
    margin-bottom: 30px;
    color: #7b7b7b; /* Gold color for title */
}

.exclude-section {
    margin-bottom: 20px;
}

.label {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
    color: #ffd700; /* Gold color for labels */
}

.input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ffd700; /* Gold border */
    border-radius: 4px;
    font-size: 1em;
    background-color: #1a1a1a; /* Dark input background */
    color: #f1f1f1; /* Light text */
}

.process-button {
    background-color: #ffd700; /* Gold button */
    color: #000000; /* Black text */
    padding: 14px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.process-button:hover {
    background-color: #ffc300; /* Darker gold on hover */
    color: #ffffff; /* White text on hover */
}

.process-button.disabled {
    background-color: #555555; /* Greyed out button */
    color: #888888; /* Grey text */
    cursor: not-allowed;
}

.airdrop-button {
    background-color: #32cd32; /* LimeGreen button */
    color: #000000; /* Black text */
    padding: 14px 25px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.airdrop-button:hover {
    background-color: #28a745; /* Darker green on hover */
    color: #ffffff; /* White text on hover */
}

.airdrop-button.disabled {
    background-color: #555555; /* Greyed out button */
    color: #888888; /* Grey text */
    cursor: not-allowed;
}

.error-message {
    color: #ff6347; /* Tomato color for errors */
    margin-top: 15px;
    font-weight: bold;
    text-align: center;
}

.progress-indicator {
    margin-top: 20px;
    text-align: center;
}

.progress-indicator p {
    margin-bottom: 10px;
    font-size: 1.1em;
    color: #ffd700; /* Gold color for progress text */
}

.progress-bar {
    width: 0%;
    height: 15px;
    background-color: #ffd700; /* Gold color for progress bar */
    border-radius: 8px;
    margin: 0 auto;
    transition: width 0.5s ease, background-color 0.5s ease;
}

.progress-bar.completed {
    background-color: #32cd32; /* LimeGreen for completion */
}

.reward-info {
    margin-top: 25px;
    background-color: #1a1a1a; /* Dark background */
    padding: 20px;
    border-left: 5px solid #ffd700; /* Gold border */
    border-radius: 4px;
}

.reward-info h4 {
    margin-bottom: 15px;
    color: #ffd700; /* Gold color */
    font-size: 1.5em;
}

.reward-info p {
    margin: 8px 0;
    color: #f1f1f1; /* Light text */
    font-size: 1.1em;
}

.rarity-totals {
    margin-top: 25px;
    background-color: #1a1a1a; /* Dark background */
    padding: 20px;
    border-left: 5px solid #17a2b8; /* Teal border */
    border-radius: 4px;
}

.rarity-totals h4 {
    margin-bottom: 15px;
    color: #ffd700; /* Gold color */
    font-size: 1.5em;
}

.rarity-totals ul {
    list-style-type: disc;
    padding-left: 40px;
    color: #f1f1f1; /* Light text */
    font-size: 1.1em;
}

.results-section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 40px;
}

/* Log Window */
.log-window {
    background-color: #1a1a1a; /* Dark background */
    border: 1px solid #ffd700; /* Gold border */
    border-radius: 4px;
    padding: 20px;
    height: 500px;
    overflow-y: scroll;
    box-shadow: 0 2px 8px rgba(255, 215, 0, 0.2); /* Gold shadow */
}

.log-window h3 {
    margin-bottom: 20px;
    color: #ffd700; /* Gold color */
    font-size: 1.8em;
}

.log-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.transaction-data,
.matching-nfts {
    border-bottom: 1px solid #ffd700; /* Gold bottom border */
    padding-bottom: 15px;
}

.transaction-data h4,
.matching-nfts h4 {
    margin-bottom: 15px;
    color: #ffd700; /* Gold color */
    font-size: 1.4em;
}

.transaction-item,
.nft-header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
    color: #ffd700; /* Gold color for icons and links */
    font-size: 1.1em;
}

.nft-item {
    padding-left: 30px;
    border-left: 3px solid #17a2b8; /* Teal left border */
    margin-bottom: 15px;
}

.nft-details p {
    margin: 6px 0;
    color: #f1f1f1; /* Light text */
    font-size: 1em;
}

.icon {
    font-size: 1.2em;
    color: #ffd700; /* Gold color for icons */
}

.link {
    color: #ffd700; /* Gold color for links */
    text-decoration: none;
    transition: color 0.2s ease;
}

.link:hover {
    color: #ffa500; /* Orange color on hover */
    text-decoration: underline;
}

.wallet-holders {
    background-color: #1a1a1a; /* Dark background */
    border: 1px solid #ffd700; /* Gold border */
    border-radius: 4px;
    padding: 25px;
    box-shadow: 0 2px 8px rgba(255, 215, 0, 0.2); /* Gold shadow */
}

.wallet-holders h3 {
    margin-bottom: 20px;
    color: #ffd700; /* Gold color */
    font-size: 1.8em;
}

.wallet-list {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.wallet-item {
    background-color: #333333; /* Slightly lighter dark background */
    border: 1px solid #ffd700; /* Gold border */
    border-radius: 4px;
    padding: 20px;
    width: calc(50% - 25px);
    box-shadow: 0 1px 4px rgba(255, 215, 0, 0.1); /* Subtle gold shadow */
}

.wallet-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.wallet-address-link {
    text-decoration: none;
}

.wallet-address {
    font-weight: bold;
    color: #ffd700; /* Gold color */
    font-size: 1.2em;
}

.wallet-icons {
    display: flex;
    gap: 8px;
}

.rarity-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #ffd700; /* Gold border around icons */
    object-fit: cover; /* Ensure the image fits within the container */
}

.wallet-item p {
    margin: 8px 0;
    color: #f1f1f1; /* Light text */
    font-size: 1.1em;
}

.wallet-details {
    margin-top: 15px;
}

.wallet-details h5 {
    margin-bottom: 8px;
    color: #ffd700; /* Gold color */
    font-size: 1.1em;
}

.wallet-details ul {
    list-style-type: disc;
    padding-left: 30px;
    color: #f1f1f1; /* Light text */
    font-size: 1em;
}

.ownership-changed {
    color: #ffa500; /* Orange color */
    margin-left: 5px;
    font-weight: bold;
}

.excluded-nfts {
    margin-top: 25px;
    background-color: #4d0000; /* Dark red background */
    border: 1px solid #ff4500; /* OrangeRed border */
    border-radius: 4px;
    padding: 20px;
}

.excluded-nfts h4 {
    margin-bottom: 15px;
    color: #ffd700; /* Gold color */
    font-size: 1.4em;
}

.csv-download {
    margin-top: 25px;
}

.csv-download h4 {
    margin-bottom: 15px;
    color: #ffd700; /* Gold color */
    font-size: 1.4em;
}

.csv-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.csv-button {
    background-color: #ffd700; /* Gold button */
    color: #000000; /* Black text */
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.csv-button:hover {
    background-color: #ffc300; /* Darker gold on hover */
    color: #ffffff; /* White text on hover */
}

/* Spinner Styles */
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto 20px auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #ffd700; /* Gold color for spinner */
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;

    animation: bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1s;
}

@keyframes bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

/* Processing Popup Styles */
.processing-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.processing-content {
    background-color: rgba(
        50,
        50,
        50,
        0.9
    ); /* Dark grey with some transparency */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    max-width: 300px;
    width: 90%;
}

.processing-info {
    margin-top: 10px;
}

.processing-step {
    font-size: 1em;
    color: #ffd700; /* Gold text */
    margin-bottom: 10px;
}

.processing-meme {
    width: 80%;
    height: auto;
    border-radius: 4px;
    margin: 10px auto;
}

.processing-quote {
    font-size: 0.9em;
    color: #f1f1f1; /* Light text */
    margin-top: 10px;
}

.processing-character {
    font-size: 0.8em;
    color: #ffa500; /* Orange text */
    margin-top: 5px;
}

/* Airdrop Details Section */
.airdrop-details {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ffd700; /* Gold border */
    border-radius: 8px;
    background-color: #1a1a1a; /* Dark background */
}

.stage-detail {
    margin-bottom: 20px;
}

.airdrop-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.airdrop-table th,
.airdrop-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.airdrop-table th {
    background-color: #f2f2f2;
    text-align: left;
    color: #000000; /* Black text for table headers */
}

.airdrop-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.airdrop-table tr:hover {
    background-color: #ddd;
}

.airdrop-table a {
    color: #ffd700; /* Gold color for links */
    text-decoration: none;
}

.airdrop-table a:hover {
    color: #ffa500; /* Orange color on hover */
    text-decoration: underline;
}

/* Confirmation Modal Styles */
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100; /* Higher than processing popup */
}

.confirmation-content {
    background-color: #1a1a1a; /* Dark background */
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px; /* Reduced max-width for smaller modal */
    max-height: 80vh; /* Limit the height to 80% of viewport height */
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
    color: #f1f1f1; /* Light text */
    box-shadow: 0 4px 16px rgba(255, 215, 0, 0.3); /* Gold shadow */
}

.confirmation-content h2 {
    margin-top: 0;
    color: #ffd700; /* Gold color for headers */
}

.airdrop-summary,
.airdrop-details {
    margin-bottom: 20px;
}

.stage-detail {
    margin-bottom: 15px;
}

.stage-detail h5 {
    margin-bottom: 10px;
    color: #ffd700; /* Gold color */
}

.stage-detail ul {
    list-style-type: none;
    padding-left: 0;
}

.stage-detail li {
    margin-bottom: 5px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.confirm-button,
.cancel-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.confirm-button {
    background-color: #4caf50; /* Green */
    color: #fff;
    transition: background-color 0.3s ease;
}

.confirm-button:hover {
    background-color: #45a049;
}

.cancel-button {
    background-color: #f44336; /* Red */
    color: #fff;
    transition: background-color 0.3s ease;
}

.cancel-button:hover {
    background-color: #e53935;
}

/* Scrollbar Styling (Optional) */
.confirmation-content::-webkit-scrollbar {
    width: 8px;
}

.confirmation-content::-webkit-scrollbar-track {
    background: #1a1a1a;
}

.confirmation-content::-webkit-scrollbar-thumb {
    background-color: #ffd700;
    border-radius: 4px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .wallet-item {
        width: 100%;
    }

    .progress-bar {
        width: 80%;
    }

    .processing-content {
        max-width: 90%;
        padding: 15px;
    }

    .processing-meme {
        width: 100%;
    }

    .airdrop-table th,
    .airdrop-table td {
        font-size: 0.9em;
        padding: 6px;
    }

    .wallet-address {
        font-size: 1em;
    }

    .wallet-icons {
        gap: 5px;
    }

    .confirmation-content {
        padding: 15px;
    }

    .confirmation-content h2 {
        font-size: 1.5em;
    }

    .confirm-button,
    .cancel-button {
        font-size: 0.9em;
        padding: 8px 16px;
    }
}
.total-ex {
    margin-top: 20px;
    background-color: #333333; /* Slightly lighter dark background */
    padding: 15px;
    border-radius: 8px;
    border-left: 5px solid #ffd700; /* Gold border */
}

.total-ex h4 {
    margin-bottom: 10px;
    color: #ffd700; /* Gold color */
}

.total-ex p,
.total-ex ul li {
    margin-bottom: 10px;
    color: #f1f1f1; /* Light text */
}
/* CSV Upload Section */
.csv-upload-section {
    margin: 20px 0;
}

.csv-upload-section .label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
}

.csv-upload-section .input {
    padding: 8px;
    width: 100%;
    max-width: 400px;
}

/* Parsed CSV Data Summary */
.csv-data-summary {
    margin: 20px 0;
}

.csv-data-summary h4 {
    margin-bottom: 8px;
}

.clear-csv-button {
    padding: 6px 12px;
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
}

.clear-csv-button:hover {
    background-color: #c0392b;
}

/* CSV Data Display */
.csv-data-display {
    margin: 20px 0;
}

.csv-data-display table {
    width: 100%;
    border-collapse: collapse;
}

.csv-data-display th,
.csv-data-display td {
    border: 1px solid #ddd;
    padding: 8px;
}

.csv-data-display th {
    background-color: #f2f2f2;
    text-align: left;
}

/* CSV Download Buttons */
.csv-download {
    margin-top: 20px;
}

.csv-download .csv-buttons {
    display: flex;
    gap: 10px;
}

.csv-download .csv-button {
    padding: 8px 16px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
}

.csv-download .csv-button:hover {
    background-color: #2980b9;
}

/* Confirmation Modal */
.confirmation-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirmation-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
}

.modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
}

.confirm-button {
    padding: 8px 16px;
    background-color: #2ecc71;
    color: white;
    border: none;
    cursor: pointer;
}

.confirm-button:hover {
    background-color: #27ae60;
}

.cancel-button {
    padding: 8px 16px;
    background-color: #e74c3c;
    color: white;
    border: none;
    cursor: pointer;
}

.cancel-button:hover {
    background-color: #c0392b;
}

/* Processing Popup */
.processing-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}

.processing-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    text-align: center;
    width: 80%;
    max-width: 500px;
}

.processing-info {
    margin-top: 20px;
}

.processing-quote {
    font-style: italic;
    margin-top: 10px;
}

.processing-character {
    margin-top: 5px;
    font-weight: bold;
}

.processing-meme {
    width: 100px;
    height: auto;
    margin-top: 10px;
}

/* Spinner Styles */
.spinner {
    width: 40px;
    height: 40px;
    position: relative;
    margin: 0 auto;
}

.double-bounce1,
.double-bounce2 {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #3498db;
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
    animation-delay: -1s;
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
    }
    50% {
        transform: scale(1);
    }
}

/* Progress Indicator */
.progress-indicator {
    margin-top: 20px;
}

.progress-bar {
    height: 20px;
    background-color: #3498db;
    border-radius: 10px;
    transition: width 0.5s;
}

.progress-bar.completed {
    background-color: #2ecc71;
}
.dohnuts-summary {
    background-color: #f9f9f9;
    border-left: 4px solid #4caf50;
    padding: 15px;
    margin: 20px 0;
    border-radius: 5px;
}

.dohnuts-summary h3 {
    margin-top: 0;
    color: #333;
}

.dohnuts-summary ul {
    list-style-type: disc;
    padding-left: 20px;
}

.dohnuts-summary li {
    margin-bottom: 5px;
}

.dohnuts-summary p {
    margin-top: 10px;
}
