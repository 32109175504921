/* Node Details */
.node-detail-view {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Split into two columns */
    gap: 10px;
    background-color: #000; /* Set to black */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    color: #ffd700;
    margin-bottom: 20px; /* Adding space between details and next section */
}

.node-detail-view h3 {
    grid-column: span 2;
    font-size: 1.2em;
    color: #ffd700;
    border-bottom: 1px solid #ffd700;
    padding-bottom: 10px;
    margin-bottom: 10px;
    text-align: center; /* Centering the title for better presentation */
}

.node-detail-view p {
    margin: 5px 0;
    font-size: 0.9em;
    color: #bbbbbb; /* Subtle contrast for detail text */
}

.node-detail-view strong {
    color: #ffd700;
}

.node-detail-view .metrics {
    grid-column: span 2;
    margin-top: 10px;
    background-color: #111; /* Dark background for metrics */
    padding: 10px;
    border-radius: 8px;
}

.metrics h4 {
    font-size: 1.1em;
    margin-bottom: 8px;
    color: #ffd700;
    border-bottom: 1px solid #ffd700;
    padding-bottom: 5px;
}

/* Button Container */
.button-container {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    margin-top: 15px;
}

.delete-button {
    background-color: #ff4c4c;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delete-button:hover {
    background-color: #ff3333;
    transform: scale(1.05);
}

.delete-button svg {
    margin-right: 5px;
    font-size: 0.9em;
}

/* Additional styles for API link */
.api-link a {
    color: #ffd700;
    text-decoration: none;
    font-size: 0.9em;
    border-bottom: 1px solid #ffd700;
    padding-bottom: 2px;
}

.api-link a:hover {
    color: #ffffff;
    border-bottom-color: #ffffff;
}
