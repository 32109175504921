/* src/games/poker/playerhand.module.css */
.playerHand {
    text-align: center;
    margin-bottom: 20px;
    background-color: #444;
    padding: 20px;
    border-radius: 8px;
    width: 220px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: border 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    margin: 20px;
}

/* Active Player Highlight */
.active {
    border: 3px solid #ffd700;
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.7);
}

/* Chip Count Indicator */
.playerHand::before {
    content: attr(data-chips);
    position: absolute;
    top: -20px;
    right: 10px;
    background-color: #ffd700;
    color: #000;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

/* Cards Container */
.cards {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-top: 15px;
}

/* Custom Card Styles */
.customCard {
    position: relative;
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    text-align: center;
    width: 60px;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 5px;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

/* Card Hover Animation */
.customCard:hover {
    transform: translateY(-5px);
    background-color: #f8f8f8;
}

/* Card Value and Suit Styling */
.cardValue {
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    top: 5px;
    left: 5px;
}

/* Suit Positioning */
.cardSuit {
    font-size: 24px;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

/* Centered Suit for Better Appearance */
.cardCenterSuit {
    font-size: 36px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* Player Turn Indicator */
.active::after {
    content: 'Your Turn';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffd700;
    color: #000;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 0 10px rgba(255, 215, 0, 0.5);
}

/* Suit Colors */
.redSuit {
    color: red;
}

.blackSuit {
    color: black;
}

/* Winner Styling */
.winner {
    background-color: #28a745;
    color: #fff;
    border: 3px solid #fff;
    box-shadow: 0 0 20px rgba(0, 255, 0, 0.7);
}

/* Responsive Design */
@media (max-width: 768px) {
    .playerHand {
        width: 180px;
        padding: 15px;
    }

    .customCard {
        width: 50px;
        height: 75px;
    }

    .cardValue {
        font-size: 14px;
    }

    .cardSuit {
        font-size: 20px;
    }

    .cardCenterSuit {
        font-size: 30px;
    }
}
