/* TotalSupply.module.css */

/* CSS Variables for Theme Colors */
:root {
    --background-color: #121212; /* Dark Black */
    --primary-color: #908f8f; /* Gold Yellow */
    --accent-color: #8a2be2; /* Blue Violet */
    --text-color: #ffffff; /* White */
    --error-bg-color: #ff4d4d; /* Red for errors */
    --matched-pool-bg: #2e2e2e; /* Slightly lighter black */
    --button-bg-color: #8a2be2; /* Purple */
    --button-hover-bg: #9933ff; /* Lighter Purple */
    --border-color: #444444; /* Dark Gray for borders */
    --select-bg-color: #1e1e1e; /* Dark Select Background */
    --select-text-color: #ffffff; /* White Select Text */
    --pre-bg-color: #1e1e1e; /* Dark background for preformatted text */
    --pre-text-color: #cccccc; /* Light gray text for JSON */
}

.container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
    border-radius: 8px;
}

.title {
    text-align: center;
    color: var(--primary-color);
    margin-bottom: 30px;
}

.matchedPoolInfo,
.errorMessage,
.totalSupply,
.transactionDetails {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
}

.matchedPoolInfo {
    background-color: var(--matched-pool-bg);
    border-color: var(--accent-color);
}

.errorMessage {
    background-color: var(--error-bg-color);
    border-color: var(--error-bg-color);
}

.errorMessage p {
    color: var(--text-color);
    margin: 0;
}

.selectionSection {
    margin-bottom: 20px;
}

.label {
    margin-right: 10px;
    font-size: 16px;
}

.selectButtonGroup {
    display: flex;
    align-items: center;
    gap: 10px;
}

.tokenSelect {
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid var(--border-color);
    background-color: var(--select-bg-color);
    color: var(--select-text-color);
    appearance: none;
    cursor: pointer;
    flex: 1;
}

.tokenSelect:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 5px var(--primary-color);
}

.fetchButton {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    background-color: var(--button-bg-color);
    color: var(--text-color);
    transition: background-color 0.3s ease;
}

.fetchButton:hover {
    background-color: var(--button-hover-bg);
}

.fetchButton:disabled {
    background-color: #555555;
    cursor: not-allowed;
}

.loadingText {
    text-align: center;
    color: var(--primary-color);
}

.totalSupply {
    background-color: #1e1e1e;
    border-color: var(--primary-color);
}

.transactionDetails {
    background-color: var(--pre-bg-color);
    border-color: var(--accent-color);
}

.transactionDetails pre {
    max-height: 400px;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: var(--pre-text-color);
    background-color: var(--pre-bg-color);
    padding: 10px;
    border-radius: 4px;
}

/* Responsive Design */

@media (max-width: 600px) {
    .selectButtonGroup {
        flex-direction: column;
        align-items: stretch;
    }

    .tokenSelect,
    .fetchButton {
        width: 100%;
        margin-bottom: 10px;
    }

    .title {
        font-size: 24px;
    }

    .label {
        font-size: 14px;
    }

    .fetchButton {
        font-size: 14px;
        padding: 8px 16px;
    }
}
