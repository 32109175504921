/* src/components/tokens/ALPHCalculator.css */

.alph-calculator-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(
        0,
        0,
        0,
        0.6
    ); /* Darker semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it overlays above other elements */
    animation: fadeIn 0.3s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.alph-calculator-container {
    background-color: #ffffff;
    padding: 25px 35px;
    border-radius: 10px;
    width: 400px;
    max-width: 90%;
    position: relative;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    animation: slideDown 0.3s ease-out;
    transition: background-color 0.3s, color 0.3s;
}

@keyframes slideDown {
    from {
        transform: translateY(-20px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.alph-calculator-container.light {
    background-color: #ffffff;
    color: #333333;
}

.alph-calculator-container.dark {
    background-color: #2c2c2c;
    color: #f0f0f0;
}

.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    color: #888888;
    transition: color 0.2s;
}

.close-button:hover {
    color: #000000;
}

.theme-toggle-button {
    position: absolute;
    top: 15px;
    left: 15px;
    background: transparent;
    border: none;
    font-size: 1.2em;
    cursor: pointer;
    color: #888888;
    transition: color 0.2s;
}

.theme-toggle-button:hover {
    color: #000000;
}

.alph-calculator-container h2 {
    margin-bottom: 20px;
    color: inherit;
    text-align: center;
    font-size: 1.5em;
}

.calculator-inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
}

.input-group {
    display: flex;
    flex-direction: column;
}

.input-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: inherit;
}

.input-group input {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 6px;
    font-size: 1em;
    background-color: inherit;
    color: inherit;
}

.input-group input:focus {
    border-color: #3b82f6;
    outline: none;
    box-shadow: 0 0 0 1px #3b82f6;
}

.input-group small {
    margin-top: 5px;
    color: #777777;
    font-size: 0.85em;
}

.calculator-result {
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
    color: inherit;
}

.error-message {
    color: #ff4d4f;
    text-align: center;
    margin-bottom: 10px;
}

.spinner {
    animation: spin 1s linear infinite;
    font-size: 1.5em;
    color: #555555;
    text-align: center;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.result-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.copy-button {
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 1em;
    color: #555555;
    transition: color 0.2s;
}

.copy-button:hover {
    color: #000000;
}

/* react-select custom styles */
.react-select__control {
    border-color: #cccccc;
    border-radius: 6px;
}

.react-select__control--is-focused {
    border-color: #3b82f6;
    box-shadow: 0 0 0 1px #3b82f6;
}

.react-select__option--is-focused {
    background-color: #e0f2fe;
}

.react-select__option--is-selected {
    background-color: #3b82f6;
    color: #ffffff;
}

.react-select__single-value {
    display: flex;
    align-items: center;
}

.react-select__single-value img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
}

.react-select__option {
    display: flex;
    align-items: center;
}

.react-select__option img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
}

/* Responsive adjustments */
@media (max-width: 500px) {
    .alph-calculator-container {
        width: 90%;
        padding: 20px 25px;
    }

    .calculator-inputs {
        gap: 15px;
    }

    .alph-calculator-container h2 {
        font-size: 1.3em;
    }

    .input-group input {
        font-size: 0.95em;
    }

    .calculator-result {
        font-size: 1.1em;
    }
}
