.app {
    background-color: #1e1e1e;
    color: #fff;
    font-family: Arial, sans-serif;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 60px 20px 20px; /* Add padding to the top */
}

/* Header Styles */
.title {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #ffd700;
}

/* Pot Information */
.potInfo {
    position: absolute;
    top: 80px; /* Adjusted to ensure it doesn't overlap with the top bar */
    left: 50%;
    transform: translateX(-50%);
    background-color: #444;
    color: #ffd700;
    padding: 10px 30px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 1.2em;
}

/* Table Styles */
.table {
    background-color: #333;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    max-width: 1000px;
    height: 400px;
    position: relative;
    padding: 20px;
    margin: 40px 0; /* Add margin to create space */
}

/* Community Cards */
.communityCards {
    display: flex;
    gap: 20px;
}

/* Custom Card Styles */
.customCard {
    background-color: #fff;
    color: #000;
    border-radius: 8px;
    text-align: center;
    width: 70px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2em;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

/* Card Hover Animation */
.customCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.7);
}

/* Card Value and Suit Styling */
.cardValue {
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 5px;
}

.cardSuit {
    font-size: 1.5em;
    margin-bottom: 5px;
}

/* Players Layout */
.players {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
}

/* Player Hand Styles */
.playerHand {
    background-color: #444;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    text-align: center;
    width: 220px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Active Player Highlight */
.active {
    border: 3px solid #ffd700;
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.9);
    transform: scale(1.05);
}

/* Winner Highlight */
.winner {
    background-color: #28a745;
    color: #fff;
    border: 3px solid #fff;
}

/* Control Buttons */
.controls {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
}

.controlButton {
    padding: 12px 25px;
    background-color: #ffd700;
    color: #000;
    border: none;
    border-radius: 8px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

.controlButton:hover {
    background-color: #ffc700;
    transform: scale(1.1);
}

/* New Game Button */
.newGameButton {
    background-color: #ffd700;
    color: #000;
    padding: 15px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.2em;
    margin-top: 40px;
    transition: background-color 0.3s, transform 0.3s;
}

.newGameButton:hover {
    background-color: #ffc700;
    transform: scale(1.1);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .table {
        width: 90%;
        height: auto;
    }

    .players {
        flex-direction: column;
        align-items: center;
    }

    .playerHand {
        width: 80%;
        margin-bottom: 20px;
    }

    .controlButton {
        width: 100%;
        text-align: center;
    }
}
