/* Sentiment Container */
.sentiment-container {
    background-color: #000;
    color: #ffd700;
    padding: 16px;
    border-radius: 8px;
    width: 100%;
    max-width: 360px;
    margin: 20px auto;
    border: 1px solid #ffd700;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    text-align: center; /* Center-align elements */
}

/* Title Section */
.title-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-bottom: 10px;
}

.sentiment-token-logo {
    width: 36px;
    height: 36px;
    object-fit: contain;
}

h2 {
    font-size: 1.5rem;
    color: #ffd700;
    margin: 0;
    text-transform: uppercase;
}

/* Vote Bar */
.vote-bar {
    display: flex;
    height: 28px;
    background-color: #1a1a1a;
    border-radius: 8px;
    overflow: hidden;
    margin: 12px 0;
    border: 1px solid #333;
    font-size: 0.85rem;
}

.bullish-bar,
.bearish-bar {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #000;
    font-weight: bold;
    transition: width 0.3s ease;
}

/* Bullish Bar */
.bullish-bar {
    background-color: #00ff00;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

/* Bearish Bar */
.bearish-bar {
    background-color: #ff4444;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

/* Reason Input */
.reason-input {
    margin: 12px 0;
    width: 100%;
}

.reason-input input {
    width: 100%;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #ffd700;
    background-color: #1a1a1a;
    color: #ffd700;
    font-size: 0.9rem;
    text-align: center;
}

/* Button Container */
.vote-buttons {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin-top: 12px;
}

/* Button Styling */
.vote-button {
    padding: 8px;
    border-radius: 6px;
    border: 1px solid transparent;
    color: #f0f0f0;
    cursor: pointer;
    flex: 1;
    transition: background-color 0.3s, transform 0.2s, border-color 0.3s;
    font-size: 0.85rem;
    font-weight: bold;
    text-transform: uppercase;
}

/* Button Colors */
.vote-button.bullish {
    background-color: #00ff00;
    color: #000;
}

.vote-button.bullish:hover {
    background-color: #32cd32;
}

.vote-button.bearish {
    background-color: #ff4444;
    color: #000;
}

.vote-button.bearish:hover {
    background-color: #ff6347;
}

/* Error Message */
.error-message {
    color: #ff4444;
    margin-top: 8px;
    font-size: 0.8rem;
}

/* Input Focus */
input:focus {
    outline: none;
    border-color: #ffd700;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sentiment-container {
        padding: 10px;
        max-width: 100%;
    }

    .vote-bar {
        height: 24px;
    }

    .vote-button {
        font-size: 0.8rem;
        padding: 6px;
    }

    h2 {
        font-size: 1.2rem;
    }
}
