/* homepage.css */

.ticker-container {
    width: 100%; /* Ensure it takes the full width */
    height: 50px; /* Adjust the height to make it taller */
    padding: 10px 0; /* Add padding for more spacing */
    overflow: hidden; /* Ensure content stays within the box */
    background-color: #111; /* Add a background color if necessary */
    border: 1px solid #ffd700; /* Border to match the theme */
    border-radius: 5px; /* Optional: Add rounded corners */
    margin-top: 20px; /* Push it down below the navbar */
}

.ticker-content {
    display: inline-block;
    white-space: nowrap;
    animation: ticker-scroll 20s linear infinite;
}

@keyframes ticker-scroll {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 15px; /* Reduced padding */
}

.home-box {
    background-color: #111;
    border: 2px solid #ffd700;
    padding: 17px; /* Reduced padding */
    border-radius: 10px;
    max-width: 420px; /* Reduced width */
    width: 100%;
    box-shadow: 0 7px 21px rgba(0, 0, 0, 0.5); /* Reduced shadow size */
    text-align: center;
    margin: 0 auto; /* Center the box horizontally */
    margin-top: 30px; /* Adjust this margin to ensure space above the TokenTicker */
}

/* Heading styles */
h1 {
    font-size: 1.26em; /* Reduced font size */
    color: #ffd700;
    margin-bottom: 14px;
}

/* Subtitle */
.subtitle {
    font-size: 0.7em; /* Reduced subtitle size */
    color: #ffd700;
    margin-bottom: 14px;
}

/* Beta button */
.beta-button {
    background-color: black;
    color: #ffd700;
    padding: 6px 11px; /* Reduced button padding */
    font-size: 0.6em; /* Reduced font size */
    border: 2px solid #ffd700;
    cursor: pointer;
    border-radius: 15px; /* Reduced border radius */
    transition: all 0.3s ease;
    width: 100%;
    max-width: 140px; /* Reduced button width */
    margin: 14px auto;
}

.beta-button:hover {
    background-color: #ffd700;
    color: black;
}

/* Form styling */
.signup-form {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Reduced gap between form fields */
    margin-top: 14px;
    align-items: center; /* Center the form elements */
}

.form-group {
    position: relative;
    width: 100%;
    max-width: 350px; /* Reduced width for form inputs */
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 8px 10px 8px 40px; /* Adjust padding to give space for the icon */
    border: 1px solid #ffd700;
    background-color: #111;
    color: #ffd700;
    border-radius: 5px;
    font-size: 0.7em; /* Reduced font size */
    box-sizing: border-box; /* Ensure padding is included in width */
}

.form-group input::placeholder,
.form-group textarea::placeholder {
    color: #ffc700;
    opacity: 0.8;
    text-align: center; /* Center the placeholder text */
}

.form-group .icon {
    position: absolute;
    top: 50%;
    left: 10px; /* Keep the icon on the left side */
    transform: translateY(-50%);
    color: #ffd700;
    font-size: 0.9em; /* Reduced icon size */
}

/* Success message */
.success-message {
    margin-top: 14px;
    color: #ffd700;
    font-size: 0.7em; /* Reduced font size */
}

/* Modal styles for user agreement */
.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
    background-color: #111;
    margin: 10% auto;
    padding: 10px; /* Reduced padding */
    border: 2px solid #ffd700;
    width: 80%;
    max-width: 350px; /* Reduced modal width */
    border-radius: 10px;
    color: #ffd700;
}

.close-modal {
    color: #ffd700;
    float: right;
    font-size: 14px; /* Reduced font size */
    font-weight: bold;
}

.close-modal:hover,
.close-modal:focus {
    color: #ffcc00;
    text-decoration: none;
    cursor: pointer;
}

/* Center the checkbox and submit button */
.checkbox-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.submit-button {
    margin-top: 14px;
    background-color: #111;
    color: #ffd700;
    padding: 8px 17px; /* Reduced padding */
    border: 1px solid #ffd700;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: 0.7em; /* Reduced font size */
}

.submit-button:hover {
    background-color: #ffd700;
    color: #111;
}

/* Adjustments for mobile */
@media (max-width: 768px) {
    .home-box {
        max-width: 100%;
        padding: 15px;
    }

    .form-group {
        max-width: 100%;
    }

    .beta-button {
        max-width: 140px;
    }
}
/* Ticker wrapper */
.ticker-container {
    position: absolute; /* Fixed positioning to keep it at the top */
    top: 60px; /* Adjust this to move below the navbar */
    left: 0;
    width: 100%;
    overflow: hidden;
    border-top: 2px solid #ffd700;
    border-bottom: 2px solid #ffd700;
}

/* Ticker content */
.ticker-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    animation: tickerMove 15s linear infinite; /* Adjust duration to slow down */
}

@keyframes tickerMove {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(-100%);
    }
}

/* Adjust spacing of ticker items */
.ticker-item {
    display: flex;
    align-items: center;
    margin-right: 30px; /* Space between items */
    color: #ffd700;
}

/* homepage.css */

/* Add these styles to center the wallet connect button */
.connect-wallet-container {
    display: flex;
    justify-content: center; /* Horizontally center */
    align-items: center; /* Vertically center if needed */
    width: 100%; /* Ensure it takes full width */
    margin: 20px 0; /* Add some margin for spacing */
}

/* Image styling for DOH Official logo */
.doh-official-logo {
    width: 100px; /* Adjust the width of the image */
    height: auto; /* Maintain aspect ratio */
    margin: 20px 0; /* Add margin to create space around the image */
    display: block; /* Ensure it is block-level to center */
    margin-left: auto;
    margin-right: auto; /* Center the image */
}

/* Styling for the link to documentation */
.docs-link {
    font-size: 0.75em; /* Adjust font size */
    color: #ffd700; /* Gold color to match the theme */
    margin-top: 10px; /* Add margin for space between text and image */
}

.docs-link a {
    color: #ffd700; /* Link color */
    text-decoration: underline; /* Optional: underline the link */
    transition: color 0.3s ease;
}

.docs-link a:hover {
    color: #ffcc00; /* Change color on hover */
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .doh-official-logo {
        width: 80px; /* Adjust the logo size for smaller screens */
    }

    .docs-link {
        font-size: 0.7em; /* Adjust font size for smaller screens */
    }
}
