/* src/games/poker/controls.css */

/* Controls container */
.controls {
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;
    flex-wrap: wrap;
}

/* Control button styles */
.control-button {
    background-color: #ffd700;
    color: #000;
    border: none;
    border-radius: 5px;
    padding: 12px 24px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Control button hover effect */
.control-button:hover {
    background-color: #ffc700;
    transform: scale(1.05);
}

/* Disabled button styling */
.control-button:disabled {
    background-color: #999;
    cursor: not-allowed;
    transform: none;
}

/* Bet slider styling */
.bet-slider {
    width: 250px;
    margin: 10px auto;
    appearance: none;
    height: 5px;
    background: #ffd700;
    border-radius: 5px;
    outline: none;
    transition: background 0.3s;
}

/* Bet slider thumb styling */
.bet-slider::-webkit-slider-thumb {
    appearance: none;
    width: 20px;
    height: 20px;
    background: #444;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
}

.bet-slider::-webkit-slider-thumb:hover {
    background: #ffc700;
}

/* Bet amount display */
.bet-amount {
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
}

/* Responsive Design */
@media (max-width: 768px) {
    .control-button {
        padding: 10px 20px;
        font-size: 14px;
    }

    .bet-slider {
        width: 200px;
    }

    .controls {
        flex-direction: column;
        gap: 10px;
    }
}
