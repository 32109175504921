/* Node Manager Container */
.node-manager-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #000; /* Full black background */
    width: 100%;
    height: calc(100vh - 80px);
    overflow-y: auto;
    color: #ffd700;
    margin-top: 80px; /* Prevent interference with navbar */
}

/* Node Stats Container */
.node-stats-container {
    display: flex;
    justify-content: space-around; /* Even spacing */
    width: 100%;
    max-width: 900px; /* Slightly wider */
    padding: 15px;
    background-color: #111;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
    color: #ffd700;
}

/* Stat Card */
.stat-card {
    background-color: #1a1a1a;
    color: #ffd700;
    border-radius: 10px;
    padding: 15px;
    width: 30%;
    text-align: center;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

/* Stat Card - Hover and Focus State */
.stat-card:hover,
.stat-card:focus {
    background-color: #2a2a2a;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
    transform: translateY(-3px);
}

/* Stat Title and Value */
.stat-title,
.stat-value {
    font-weight: bold;
    color: #ffd700;
    margin-bottom: 5px;
}

.stat-title {
    font-size: 1.1em;
}

.stat-value {
    font-size: 1.4em;
    margin-top: 8px;
}

/* Node Table */
.node-table {
    width: 100%;
    max-width: 1200px;
    margin-top: 20px;
    color: #ffd700;
    border-collapse: collapse;
    border-radius: 8px;
    overflow: hidden;
}

.node-table th,
.node-table td {
    padding: 14px;
    text-align: left;
    border-bottom: 1px solid #444;
}

.node-table th {
    background-color: #111;
    font-weight: bold;
    font-size: 1em;
}

.node-table td {
    background-color: #2a2a2a;
}

.node-table tr.expanded td {
    background-color: #3a3a3a;
}

/* Section Title */
.section-title {
    font-size: 1.6em;
    margin-bottom: 20px;
    color: #ffd700;
    text-align: center;
    letter-spacing: 0.05em;
}

/* Search Container */
.search-container {
    width: 100%;
    max-width: 600px;
    margin-bottom: 20px;
    align-self: center;
}

.search-input {
    width: 100%;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #555;
    background-color: #2a2a2a;
    color: #ffd700;
    font-size: 1em;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
}

/* Button Group */
.button-group {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 25px;
}

/* Deploy Button */
.deploy-button {
    background: linear-gradient(135deg, #333, #000);
    color: #ffd700;
    border: 1px solid #ffd700;
    padding: 12px 24px;
    cursor: pointer;
    font-size: 1em;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    transition: background-color 0.3s, box-shadow 0.3s, transform 0.3s;
}

.deploy-button:hover,
.deploy-button:focus {
    background-color: #555;
    transform: translateY(-3px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.6);
}

/* Logs Section */
.logs-section,
.console-logs-section {
    width: 48%;
    background-color: #000;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
    margin-top: 20px;
    height: 320px;
    overflow-y: auto;
}

/* Logs Output */
.logs-output,
.console-logs-output {
    background-color: #333;
    padding: 18px;
    border-radius: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    color: #ffd700;
}

/* Bottom Logs Container */
.bottom-logs-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    gap: 20px;
    margin-top: 20px;
}

/* Title and Logo Styling */
.title-container {
    text-align: center;
    margin-bottom: 20px;
}

.page-title {
    font-size: 2em;
    color: #ffd700;
}

/* Alph Logo */
.alph-logo {
    content: url('/src/assets/logos/alphbanner.png');
    width: 120px;
    height: auto;
}

/* Focus State Styling */
:focus {
    outline: 2px solid #ffd700;
    outline-offset: 4px;
}
