/* Toolbar Container Styling */
.toolbar-container {
    display: flex;
    justify-content: space-between; /* Aligns feedback and filter buttons to opposite ends */
    align-items: center; /* Vertically aligns the items */
    width: 100%; /* Ensures the toolbar spans the entire width of the token table */
    padding: 10px 20px; /* Adds space between the toolbar and the table and adds horizontal padding */
    background-color: #000; /* Matches the background with the token table */
}

.unique-filter-dropdown {
    display: flex;
    flex-direction: column;
    background-color: #000;
    padding: 10px;
    box-shadow: 0px 0px 10px rgba(255, 215, 0, 0.8);
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.unique-filter-dropdown-item {
    padding: 5px 8px; /* Compact padding for dropdown items */
    color: #ffd700;
    font-size: 0.75rem; /* Slightly smaller font for a cleaner look */
    cursor: pointer;
}

.unique-filter-dropdown-item label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.unique-filter-dropdown-item input {
    margin-right: 8px; /* Adds space between checkbox and label */
    width: 12px; /* Smaller checkboxes */
    height: 12px; /* Smaller checkboxes */
}

/* Feedback Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #000;
    padding: 15px; /* Compact padding */
    border-radius: 5px;
    max-width: 400px;
    width: 100%;
    box-sizing: border-box;
    overflow-wrap: break-word;
    border: 1px solid #ffd700;
}

.modal-header {
    text-align: center;
    font-size: 1.3rem; /* Slightly smaller font size */
    margin-bottom: 15px;
    color: #ffd700;
    font-weight: bold;
}

textarea {
    width: 100%;
    height: 80px; /* Reduced height */
    margin-bottom: 15px; /* Reduced margin */
    background: #000;
    color: #fff;
    border: 1px solid #ffd700;
    border-radius: 5px;
    padding: 8px; /* Compact padding */
    resize: none;
    font-size: 0.9rem; /* Slightly smaller font */
    line-height: 1.5;
}

.button-group {
    text-align: left;
}

.submit-button {
    background: none;
    border: 1px solid #ffd700;
    cursor: pointer;
    color: #ffd700;
    font-size: 0.8rem; /* Slightly smaller font size */
    padding: 6px 10px; /* Compact padding */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.submit-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.telegram-icon {
    font-size: 1.5rem; /* Adjusted icon size */
    color: #ffd700;
}

.thank-you-message {
    color: #fff;
    text-align: center;
    font-size: 0.9rem; /* Reduced font size */
    line-height: 1.4;
}

.thank-you-message a {
    color: #ffd700;
    text-decoration: underline;
    display: block;
    margin-top: 10px;
    word-wrap: break-word;
}

/* Button Styling for All Buttons */
button,
.feedback-button,
.submit-button,
.instant-feedback-button {
    background: #000;
    border: 0.5px solid #ffd700;
    cursor: pointer;
    color: #ffd700;

    padding: 8px 16px; /* Compact padding */
    border-radius: 6px; /* Compact button radius */
    display: inline-flex;
    align-items: center;
    justify-content: left;
    transition: background-color 0.3s ease, color 0.3s ease,
        box-shadow 0.3s ease;
}

button:hover,
.feedback-button:hover,
.submit-button:hover,
.instant-feedback-button:hover {
    background-color: #ffd700;
    color: #000;
    box-shadow: 0px 0px 10px rgba(255, 215, 0, 0.8); /* Glow effect on hover */
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
