/* walletmodal.css */

/* Modal overlay */
.wallet-modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* High z-index to overlay other elements */
}

/* Modal container */
.wallet-modal-container {
    position: relative;
    width: 100%;
    max-width: 640px;
}

/* Modal box */
.wallet-modal {
    background-color: #1e1e1e;
    padding: 2rem;
    border-radius: 12px;
    color: #fff;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Modal header */
.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.modal-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #fff;
}

.close-modal-btn {
    background: none;
    border: none;
    color: #c0c9d4;
    cursor: pointer;
}

.close-icon {
    width: 1.5rem;
    height: 1.5rem;
}

/* Modal content */
.modal-content {
    gap: 2rem;
}

/* Wallet options */
.wallet-options {
    flex: 1;
}

.section-title {
    color: #8f9ca7;
    font-size: 1.1rem;
    margin-bottom: 1rem;
}

.wallet-option {
    background-color: #2c2c2c;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #d1d9e6;
    transition: background-color 0.3s ease;
    width: 100%;
    text-align: left;
    font-size: 1.1rem;
    gap: 10px;
    margin-bottom: 0.5rem; /* Space between buttons */
}

.wallet-option img {
    height: 24px;
    width: 24px;
}

.wallet-option:hover {
    background-color: #3a3a3a;
}

.wallet-option.disabled {
    opacity: 0.4;
    cursor: not-allowed;
}

/* Wallet info section */
.wallet-info-section {
    flex: 1;
}

.wallet-info-text {
    font-size: 1.15rem;
    color: #d1d1d1;
    margin-bottom: 1rem;
    font-weight: bold;
}

.wallet-info-description {
    font-size: 0.95rem;
    color: #c0c9d4;
    line-height: 1.5;
}

.wallet-info-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 1.5rem;
}

.get-wallet-btn {
    background: linear-gradient(to right, #a56eff, #f373ff);
    color: white;
    text-align: center;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    flex-grow: 1;
    text-decoration: none;
}

.learn-more-btn {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    flex-grow: 1;
    text-decoration: none;
}

.get-wallet-btn:hover {
    opacity: 0.9;
}

.learn-more-btn:hover {
    background-color: #444;
}
