/* src/components/tokenlist_test.css */

/* ================================
   General Container Styles
=============================== */
.token-list-container {
  min-height: 100vh;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.token-list-container.dark {
  background-color: #121212;
  color: #ffffff; /* Default text color in dark mode */
}

.token-list-container.light {
  background-color: #f5f5f5;
  color: #000000; /* Default text color in light mode */
}

/* ================================
   Address Link Styles
=============================== */
.address-link {
  color: #FFD700; /* Gold color */
  text-decoration: none;
  font-weight: bold;
}

.address-link:hover {
  text-decoration: underline;
  color: #ffeb3b; /* Brighter gold on hover */
}

/* ================================
   Avatar Styles
=============================== */
.token-avatar {
  margin-right: 8px;
  border: 1px solid transparent; /* Default border */
  transition: border 0.3s ease;
}

.token-list-container.dark .token-avatar {
  border-color: #FFD700; /* Gold border in dark mode */
}

.token-list-container.light .token-avatar {
  border-color: transparent; /* No border in light mode */
}

/* ================================
   Table Styles
=============================== */
.MuiTableCell-root {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Light border */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.table-header {
  background-color: rgba(255, 215, 0, 0.1); /* Subtle gold background */
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-header .MuiTableCell-root {
  font-weight: bold;
  color: #FFD700; /* Gold color for header text */
}

.table-row {
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.table-row:hover {
  background-color: rgba(255, 215, 0, 0.2); /* Slightly darker gold on hover */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transform: scale(1.01); /* Slight scale up */
  border-radius: 8px; /* Rounded corners on hover */
}

/* Expanded Row Class */
.expanded-row-class {
  background-color: rgba(255, 215, 0, 0.05); /* Light gold for expanded rows */
}

/* ================================
   Routed Transaction Indicator Styles
=============================== */
.routed-indicator {
  text-align: center;
  width: 60px; /* Adjust as needed */
}

.routed-indicator .MuiSvgIcon-root {
  color: #ff9800; /* Orange color to stand out */
}

/* Optional: Highlight entire row if it's a routed transaction */
.routed-transaction-row {
  background-color: rgba(255, 215, 0, 0.05); /* Light gold background */
}

/* ================================
   Collapse Content Styles
=============================== */
.collapse-content {
  padding: 16px;
  background-color: inherit;
  color: inherit;
  transition: all 0.3s ease;
}

.collapse-content h6 {
  display: flex;
  align-items: center;
  color: #FFD700; /* Gold color for section headers */
  margin-bottom: 16px;
}

.collapse-content h6 .MuiSvgIcon-root {
  margin-right: 8px;
}

/* ================================
   Typography Styles
=============================== */
.token-name {
  color: #FFD700;
  font-weight: 600;
  transition: color 0.3s ease;
}

.MuiTypography-body2 {
  color: inherit; /* Ensure text color adapts to theme */
}

.metric {
  font-weight: bold;
  color: #ffffff; /* White color for values */
}

.label {
  font-weight: bold;
  color: #FFD700; /* Yellow color for labels */
}

.value {
  color: #ffffff; /* White color for values */
}

.description {
  margin-top: 8px;
  color: #bdbdbd; /* Slightly lighter color for descriptions */
}

/* ================================
   Responsive Adjustments
=============================== */
/* Hide APR and Price columns on medium screens */
@media (max-width: 960px) {
  .MuiTableCell-root.apr-column,
  .MuiTableCell-root.price-column {
      display: none;
  }
}

/* Adjust padding and font sizes on small screens */
@media (max-width: 600px) {
  .token-list-container {
      padding: 1rem;
  }

  .MuiTableCell-root {
      padding: 8px;
      font-size: 0.9rem; /* Slightly smaller font on mobile */
  }

  .collapse-content {
      padding: 8px;
  }

  /* Adjust pool logos and avatars */
  .pool-logo,
  .pool-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .token-avatar {
    margin-right: 6px;
  }

  /* Stack buttons vertically on mobile */
  .collapse-content .MuiButton-root {
    width: 100%;
    margin-bottom: 8px;
  }
}

/* ================================
   Responsive Table Container
=============================== */
.responsive-table-container {
  overflow-x: auto;
}

/* ================================
   Pool Sections Styles
=============================== */
/* Elexium Pools Background */
.elexium-pools-section {
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(0, 123, 255, 0.1); /* Light blue for light mode */
}

.token-list-container.dark .elexium-pools-section {
  background-color: rgba(0, 123, 255, 0.2); /* Blueish background for dark mode */
}

.token-list-container.light .elexium-pools-section {
  background-color: rgba(0, 123, 255, 0.1); /* Light blue for light mode */
}

/* Ayin Pools Background */
.ayin-pools-section {
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 215, 0, 0.1); /* Light gold for light mode */
}

.token-list-container.dark .ayin-pools-section {
  background-color: rgba(255, 215, 0, 0.2); /* Goldish background for dark mode */
}

.token-list-container.light .ayin-pools-section {
  background-color: rgba(255, 215, 0, 0.1); /* Light gold for light mode */
}

/* ================================
   Pool Item Styles
=============================== */
/* Styles for individual pool items */
.pool-item {
  margin-bottom: 1rem;
}

/* Hover effects for pool sections */
.elexium-pools-section:hover,
.ayin-pools-section:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* ================================
   Pool Icon Styles
=============================== */
/* Styles for pool logos and icons */
.pool-logo {
  width: 24px; /* Adjust size as needed */
  height: 24px;
  border-radius: 50%; /* Circular shape */
  margin-right: 8px;
  object-fit: cover; /* Ensure proper scaling */
  background-color: #f0f0f0; /* Fallback background */
}

/* Additional Pool Icon Styling for smaller icons */
.pool-icon {
  width: 24px; /* Adjust size as needed */
  height: 24px;
  border-radius: 50%; /* Circular shape */
  margin-right: 4px; /* Spacing between icons */
  object-fit: cover; /* Ensure proper scaling */
  background-color: #f0f0f0; /* Fallback background */
}

/* ================================
   Transactions Section Styles
=============================== */
/* Styles for the transactions section within expanded rows */
.transactions-section {
  margin-top: 16px;
}

/* ================================
   Token List Styles
=============================== */
/* Styles for token lists in expanded transaction details */
.token-list {
  list-style-type: none;
  padding: 0;
}

.token-sent {
  color: #f44336; /* Red for sent tokens */
}

.token-received {
  color: #4caf50; /* Green for received tokens */
}

/* ================================
   Badge Styles
=============================== */
/* General badge styles */
.badge {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 0.8rem;
  white-space: nowrap; /* Prevent badges from wrapping */
}

/* Specific badge types */
.got-badge {
  background-color: #4caf50; /* Green */
  color: #fff;
}

.for-badge {
  background-color: #2196f3; /* Blue */
  color: #fff;
}

/* Highlighted badges for selected tokens */
.got-selected-badge {
  background-color: #4caf50 !important; /* Green */
}

.for-selected-badge {
  background-color: #f44336 !important; /* Red */
}

/* ================================
   External Link Styles
=============================== */
/* Styles for external links within transaction details */
.external-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.external-link .MuiSvgIcon-root {
  margin-left: 4px;
}

/* ================================
   Loading and Error States Styles
=============================== */
/* Styles for loading indicators */
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-text {
  margin-left: 10px;
}

/* Styles for error alerts */
.error-alert {
  margin-bottom: 20px;
}

/* ================================
   Miscellaneous Styles
=============================== */
/* Ensure smooth transitions for interactive elements */
.interactive-element {
  transition: all 0.3s ease;
}

/* Styling for transaction details sections */
.transaction-details {
  background-color: inherit;
  color: inherit;
  padding: 16px;
}

/* Styling for flow sections within transaction details */
.flow-section {
  margin-top: 16px;
}

.flow-title {
  font-weight: bold;
  color: #FFD700; /* Gold color for titles */
}

.flow-subtitle {
  font-weight: bold;
  color: #2196f3; /* Blue color for subtitles */
}

/* No data placeholder styles */
.no-data {
  color: #bdbdbd; /* Light gray for no data */
}

/* ================================
   Responsive Design Enhancements
=============================== */
/* Adjustments for smaller screens */
@media (max-width: 600px) {
  .pool-logo,
  .pool-icon {
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }

  .token-avatar {
    margin-right: 6px;
  }

  /* Stack buttons vertically on mobile */
  .collapse-content .MuiButton-root {
    width: 100%;
    margin-bottom: 8px;
  }

  /* Adjust Typography */
  .token-name {
    font-size: 1rem;
  }

  .MuiTypography-body2 {
    font-size: 0.85rem;
  }
}

/* ================================
   Hover and Active States
=============================== */
/* Hover effect for address links */
.address-link:hover {
  color: #ffeb3b; /* Brighter gold on hover */
}

/* Active state for buttons */
.MuiIconButton-root:active {
  transform: scale(0.95);
}

/* ================================
   Table and Dropdown Styles
=============================== */
/* Ensure that tables take full width */
.table-container {
  width: 100%;
}

/* Ensure that token selection dropdowns are full width */
.token-selection {
  width: 100%;
}

/* Styles for pool names */
.pool-name {
  display: flex;
  align-items: center;
  color: #FFD700; /* Gold color */
}

/* Ensure that transaction details have proper spacing */
.transaction-details {
  padding: 16px;
}

/* Additional transitions for smooth interactions */
.expand-icon {
  transition: transform 0.3s ease;
}

.expand-icon:hover {
  transform: rotate(180deg);
}

/* ================================
   Detail Paper Styles
=============================== */
/* Styles for Paper components encapsulating detail sections */
.detail-paper {
  padding: 16px;
  margin-bottom: 16px;
}

.detail-title {
  font-weight: bold;
  color: #FFD700; /* Gold color for titles */
  margin-bottom: 8px;
}
