/* Container Styles */
.token-list-container {
    min-height: 100vh;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.token-list-container.dark {
    background-color: #121212;
    color: #ffffff; /* Default text color in dark mode */
}

.token-list-container.light {
    background-color: #f5f5f5;
    color: #000000; /* Default text color in light mode */
}

/* Address Link Styles */
.address-link {
    color: #ffd700;
    text-decoration: none;
    font-weight: bold;
}

.address-link:hover {
    text-decoration: underline;
}

/* Avatar Styles */
.token-avatar {
    margin-right: 8px;
    border: 1px solid transparent; /* Default border */
    transition: border 0.3s ease;
}

.token-list-container.dark .token-avatar {
    border-color: #7b7b7b; /* Gold border in dark mode */
}

.token-list-container.light .token-avatar {
    border-color: transparent; /* No border in light mode */
}

/* Table Styles */
.MuiTableCell-root {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Light border for dark mode */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.token-table-header {
    background-color: rgba(
        255,
        215,
        0,
        0.1
    ); /* Subtle gold background for headers */
    position: sticky;
    top: 0;
    z-index: 1;
}

.token-table-header .MuiTableCell-root {
    font-weight: bold;
    color: #ffd700; /* Gold color for header text */
}

.token-table-row:hover {
    background-color: rgba(
        255,
        215,
        0,
        0.05
    ); /* Light gold hover for dark mode */
    transition: background-color 0.3s ease;
}

/* Enhanced Hover Effects */
.token-table-row:hover {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(255, 215, 0, 0.2);
    border-radius: 8px;
}

/* Collapse Content Styles */
.collapse-content {
    padding: 16px;
    background-color: inherit;
    color: inherit;
    transition: all 0.3s ease;
}

.collapse-content h6 {
    display: flex;
    align-items: center;
    color: #ffd700; /* Gold color for section headers */
    margin-bottom: 16px;
}

.collapse-content h6 .MuiSvgIcon-root {
    margin-right: 8px;
}

/* Typography Styles */
.token-name {
    color: #ffd700;
    font-weight: 600;
    transition: color 0.3s ease;
}

.MuiTypography-body2 {
    color: inherit; /* Ensure text color adapts to theme */
}

.metric {
    font-weight: bold;
    color: #ffffff; /* White color for values */
}

.label {
    font-weight: bold;
    color: #ffd700; /* Yellow color for labels */
}

.value {
    color: #ffffff; /* White color for values */
}

.description {
    margin-top: 8px;
    color: #bdbdbd; /* Slightly lighter color for descriptions */
}

/* Responsive Adjustments */
@media (max-width: 960px) {
    .MuiTableCell-root.apr-column,
    .MuiTableCell-root.price-column {
        display: none; /* Hide APR and On-Chain Price on medium screens */
    }
}

@media (max-width: 600px) {
    .token-list-container {
        padding: 1rem;
    }

    .MuiTableCell-root {
        padding: 8px;
        font-size: 0.9rem; /* Slightly smaller font on mobile */
    }

    .collapse-content {
        padding: 8px;
    }
}

/* Additional Styles */

/* Styling for Expand/Collapse Icons */
.expand-icon {
    color: #ffd700;
    transition: transform 0.3s ease;
}

.MuiIconButton-root:active .expand-icon {
    transform: scale(0.9);
}

/* Enhanced Scrollbar Styling */
.token-list-container.dark ::-webkit-scrollbar {
    width: 8px;
}

.token-list-container.dark ::-webkit-scrollbar-track {
    background: #2c2c2c;
}

.token-list-container.dark ::-webkit-scrollbar-thumb {
    background-color: #ffd700;
    border-radius: 4px;
}

.token-list-container.light ::-webkit-scrollbar {
    width: 8px;
}

.token-list-container.light ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.token-list-container.light ::-webkit-scrollbar-thumb {
    background-color: #ffd700;
    border-radius: 4px;
}

/* Responsive Table Container */
.responsive-table-container {
    overflow-x: auto;
}

/* Smooth Transition for Collapse */
.MuiCollapse-root {
    transition: all 0.3s ease;
}

/* Sticky Header Styles */
.MuiTableHead-root {
    background-color: rgba(255, 215, 0, 0.1);
}

.MuiTableHead-root .MuiTableCell-root {
    position: sticky;
    top: 0;
    z-index: 2;
}
/* src/components/tokenlist_test.css */

/* General styles for pool sections */
.elexium-pools-section,
.ayin-pools-section {
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
}

/* Elexium Pools Background */
.dark .elexium-pools-section {
    background-color: rgba(
        255,
        215,
        0,
        0.2
    ); /* Goldish background for dark mode */
}

.light .elexium-pools-section {
    background-color: rgba(255, 215, 0, 0.1); /* Light gold for light mode */
}

/* Ayin Pools Background */
.dark .ayin-pools-section {
    background-color: rgba(
        0,
        123,
        255,
        0.2
    ); /* Blueish background for dark mode */
}

.light .ayin-pools-section {
    background-color: rgba(0, 123, 255, 0.1); /* Light blue for light mode */
}

/* Additional styling to make pool items stand out */
.pool-item {
    margin-bottom: 1rem;
}

/* Optional: Add hover effects or borders if desired */
.elexium-pools-section:hover,
.ayin-pools-section:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Ensure address links have appropriate styling */
.address-link {
    color: inherit;
    text-decoration: underline;
}

.address-link:hover {
    text-decoration: none;
}
