/* src/dohnutrewards.css */

/* 
  Global Styles
*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #1D1A27; /* Dark Purple-ish background */
  color: #EAEAEA; 
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
}

/* 
  Layout
*/
.rewards-platform-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

/* 
  Header
*/
.header-bar {
  text-align: center;
  margin-bottom: 2rem;
  border-bottom: 2px solid rgba(255, 215, 0, 0.2);
  padding-bottom: 1rem;
}

.main-header {
  font-size: 2.2rem;
  letter-spacing: 1px;
  color: #FFD700; /* Gold */
  text-shadow: 1px 1px 3px #9E4F9E; /* Subtle purple shadow */
}

/*
  Footer
*/
.footer {
  text-align: center;
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 2px solid rgba(255, 215, 0, 0.2);
  font-size: 0.85rem;
  color: #b9b9b9;
}

.footer a {
  color: #FFD700;
}

/* 
  Collapsible Card
*/
.collapsible-card {
  background-color: #2A2734;
  margin-bottom: 1.5rem;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #3F3B51;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.3);
}

.collapsible-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: linear-gradient(90deg, #9B59B6, #3F2B96);
  color: #fff;
  transition: background 0.3s ease;
}

.collapsible-header:hover {
  background: linear-gradient(90deg, #B67EE5, #5C3BBF);
}

.collapsible-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.chevron {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
  margin-left: 1rem;
}

.chevron.open {
  transform: rotate(225deg);
}

.collapsible-content {
  padding: 1rem 1.5rem;
  background-color: #2A2734;
}

/* 
  Loading & Error
*/
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 4rem;
}

.spinner {
  border: 6px solid #3F3B51;
  border-top: 6px solid #FFD700;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.8s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.error-container {
  text-align: center;
  margin-top: 3rem;
}

.error-message {
  display: inline-block;
  padding: 2rem;
  background-color: #3F3B51;
  color: #FFD700;
  border: 2px solid #FFD700;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.2);
}

/* 
  Search Bars
*/
.search-bar {
  margin: 1rem 0;
  text-align: right;
}

.search-bar input {
  padding: 0.5rem;
  border-radius: 4px;
  border: none;
  width: 250px;
  outline: none;
  background-color: #413B53;
  color: #EAEAEA;
}

.search-bar input::placeholder {
  color: #b7b7b7;
}

/* 
  Tables
*/
.table-responsive {
  overflow-x: auto;
  margin-top: 1rem;
  border: 1px solid #3F3B51;
  border-radius: 8px;
}

.reward-table, .nft-table, .epoch-table {
  width: 100%;
  border-collapse: collapse;
  color: #EAEAEA;
}

.reward-table thead, .nft-table thead, .epoch-table thead {
  background-color: #413B53;
}

.reward-table th, .nft-table th, .epoch-table th {
  text-align: left;
  padding: 0.8rem;
  font-weight: 600;
  color: #FFD700;
}

.reward-table td, .nft-table td, .epoch-table td {
  padding: 0.8rem;
  border-bottom: 1px solid #3F3B51;
  vertical-align: middle;
}

.table-responsive table tr:hover {
  background-color: rgba(255, 215, 0, 0.03);
}

.reward-table tr:hover, .nft-table tr:hover, .epoch-table tr:hover {
  background-color: rgba(255, 215, 0, 0.03);
}

/* 
  RewardPool
*/
.reward-pool {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.8rem;
}

.reward-pool-item {
  display: flex;
  flex-direction: column;
  background-color: #3F3B51;
  border-radius: 8px;
  padding: 1rem;
  align-items: flex-start;
  justify-content: center;
}

.reward-pool-item .label {
  font-weight: bold;
  color: #FFD700;
}

.reward-pool-item .value {
  margin-top: 0.3rem;
  font-size: 1.1rem;
}

/* 
  WalletRewards
*/
.wallet-row:hover {
  background-color: rgba(155, 89, 182, 0.1);
}

/* 
  NFTs
*/
.nft-row {
  transition: background 0.2s ease;
}
.nft-row:hover {
  background-color: rgba(255, 215, 0, 0.03);
}

.badge {
  display: inline-block;
  padding: 0.3rem 0.6rem;
  border-radius: 4px;
  color: #fff;
  font-size: 0.85rem;
  text-transform: uppercase;
  font-weight: bold;
}

.badge-legendary {
  background-color: #FFD700; 
  color: #3F2B96;
}

.badge-epic {
  background-color: #8E44AD;
  color: #FFD700;
}

.badge-rare {
  background-color: #2980B9;
  color: #fff;
}

.badge-common {
  background-color: #95A5A6;
  color: #2C3E50;
}

.badge-unknown {
  background-color: #6c757d;
  color: #fff;
}

/* 
  PastEpochRewards
*/
.epoch-table th,
.epoch-table td {
  text-align: left;
}

/* 
  WhatAreDohnuts
*/
.note {
  margin-top: 1rem;
  font-size: 0.9rem;
  color: #FFD700;
}

.wallet-address {
  color: #FFD700;
  text-shadow: 0px 0px 2px #FFD700;
}

/* 
  Chart Containers
*/
.chart-container {
  margin: 1.5rem 0;
  padding: 1rem;
  background-color: #2A2734;
  border: 1px solid #3F3B51;
  border-radius: 8px;
}

.pie-chart-container,
.line-chart-container,
.bar-chart-container {
  width: 100%;
  height: 300px;
}

.analytics-charts {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* 
  Responsive Design for Charts
*/
@media screen and (min-width: 768px) {
  .analytics-charts {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .analytics-charts > .collapsible-card {
    flex: 1 1 45%;
  }
}

/* 
  Additional Styling for Recharts Components
*/
.recharts-tooltip-wrapper {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  padding: 0.5rem;
}

.recharts-legend-wrapper {
  font-size: 0.9rem;
}

/* 
  Chart Titles
*/
.collapsible-card .collapsible-header h2 {
  font-size: 1.2rem;
}

/* 
  Responsive Tables
*/
.table-responsive {
  overflow-x: auto;
  margin-top: 1rem;
  border: 1px solid #3F3B51;
  border-radius: 8px;
}

/* 
  Additional Classes for Responsive Layouts
*/
@media screen and (max-width: 768px) {
  .reward-pool {
    grid-template-columns: 1fr;
  }

  .analytics-charts {
    flex-direction: column;
  }

  .analytics-charts > .collapsible-card {
    flex: 1 1 100%;
  }

  .search-bar input {
    width: 100%;
  }
}

/* 
  Footer Links Styling
*/
.footer a {
  color: #FFD700;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}

/* 
  Table Row Links
*/
.wallet-link, .holder-link, .token-link {
  color: #FFD700;
  text-decoration: none;
}

.wallet-link:hover, .holder-link:hover, .token-link:hover {
  text-decoration: underline;
}

/* 
  Badge Hover Effects
*/
.badge:hover {
  opacity: 0.8;
}

/* 
  Search Bar Hover Effects
*/
.search-bar input:focus {
  background-color: #5C3BBF;
}

/* 
  General Button Styles (if any buttons are added later)
*/
button {
  padding: 0.5rem 1rem;
  background-color: #FFD700;
  color: #1D1A27;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #E6B800;
}
