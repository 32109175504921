/* src/components/TokenBubbles/tokenbubbles.css */

:root {
    /* Colors */
    --color-background: #000000;
    --color-text: #ffffff;
    --color-shadow: rgba(0, 0, 0, 0.5);

    --gradient-positive-start: #00ff7f;
    --gradient-positive-end: #7fff00;
    --gradient-negative-start: #ff4500;
    --gradient-negative-end: #ff6347;

    --color-bubble-bg: rgba(0, 0, 0, 0.6);
    --color-bubble-hover-bg: rgba(0, 0, 0, 0.8);

    --color-price-up: #00ff00;
    --color-price-down: #ff0000;

    /* Typography */
    --font-primary: 'Roboto', sans-serif;

    /* Sizes */
    --size-bubble-min: 100px; /* Increased from 80px */
    --size-bubble-max: 200px; /* Increased from 150px */

    /* Typography Sizes */
    --font-size-title: 1.5rem; /* Increased for better visibility */
    --font-size-price: 1.2rem; /* Increased */
    --font-size-price-change: 1rem; /* Increased */

    /* Transitions */
    --transition-speed: 0.5s;
    --transition-ease: ease;

    /* Breakpoints */
    --breakpoint-tablet: 768px;
    --breakpoint-mobile: 480px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.bubbles-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: var(--color-background);
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

/* Bubble Styles */
.bubble {
    position: absolute;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform var(--transition-speed) var(--transition-ease),
        box-shadow var(--transition-speed) var(--transition-ease);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    background: transparent;
    will-change: transform, box-shadow;
}

.bubble:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.5);
}

/* Bubble Border with Gradient */
.bubble__border {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 6px; /* Increased padding for thicker border */
    background-clip: padding-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
        135deg,
        var(--gradient-positive-start),
        var(--gradient-positive-end)
    );
}

/* Modifier for Negative Gradient */
.bubble--negative .bubble__border {
    background: linear-gradient(
        135deg,
        var(--gradient-negative-start),
        var(--gradient-negative-end)
    );
}

/* Bubble Content */
.bubble__content {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: var(--color-bubble-bg);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
    box-shadow: inset 0 0 15px var(--color-shadow); /* Increased inset shadow for depth */
    transition: background var(--transition-speed) var(--transition-ease);
    font-family: var(--font-primary);
}

.bubble:hover .bubble__content {
    background: var(--color-bubble-hover-bg);
}

/* Bubble Logo */
.bubble__logo {
    width: 60%; /* Increased size */
    height: 60%; /* Increased size */
    object-fit: contain;
    border-radius: 50%;
    transition: transform var(--transition-speed) var(--transition-ease);
}

.bubble:hover .bubble__logo {
    transform: rotate(20deg);
}

/* Bubble Info */
.bubble__info {
    position: absolute;
    bottom: 15%; /* Adjusted for better spacing */
    text-align: center;
    color: var(--color-text);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.7); /* Enhanced text shadow */
}

.bubble__info-title {
    margin: 0;
    font-size: var(--font-size-title); /* Updated font size */
    font-weight: 700;
    font-family: var(--font-primary);
}

/* Removed the price styling
.bubble__info-price {
  margin: 5px 0;
  font-size: var(--font-size-price); 
  font-weight: 400;
  font-family: var(--font-primary);
}
*/

.bubble__price-change {
    font-size: var(--font-size-price-change); /* Updated font size */
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-primary);
}

.bubble__price-change svg {
    margin-right: 4px;
}

.bubble__price-change--positive {
    color: var(--color-price-up);
}

.bubble__price-change--negative {
    color: var(--color-price-down);
}

/* Focus Styles for Accessibility */
.bubble:focus {
    outline: 3px dashed var(--gradient-positive-start);
    outline-offset: 4px;
    box-shadow: 0 0 0 3px rgba(0, 255, 127, 0.5);
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.token-modal {
    background: rgba(0, 0, 0, 0.85); /* Slightly darker for better contrast */
    padding: 25px; /* Increased padding */
    border-radius: 12px; /* Rounded corners */
    color: var(--color-text);
    max-width: 600px; /* Increased max width */
    width: 90%;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.6); /* Enhanced shadow */
    font-family: var(--font-primary);
}

.token-modal h2 {
    margin-top: 0;
    font-size: 2rem; /* Increased font size */
}

.token-modal p {
    font-size: 1.2rem; /* Increased font size */
    margin: 10px 0;
}

.token-modal button {
    margin-top: 25px; /* Increased margin */
    padding: 12px 24px; /* Increased padding */
    background-color: #ff6347;
    border: none;
    border-radius: 6px; /* Rounded corners */
    color: var(--color-text);
    cursor: pointer;
    font-size: 1.2rem; /* Increased font size */
    transition: background-color 0.3s ease;
}

.token-modal button:hover {
    background-color: #ff4500;
}

/* Responsive Design */
@media (max-width: var(--breakpoint-tablet)) {
    .bubble__info-title {
        font-size: 1.3rem; /* Slightly reduced for smaller screens */
    }

    /* Removed the price styling
  .bubble__info-price {
    font-size: 1rem;
  }
  */

    .bubble__price-change {
        font-size: 0.9rem;
    }

    .bubble__logo {
        width: 50%;
        height: 50%;
    }

    .token-modal h2 {
        font-size: 1.8rem;
    }

    .token-modal p {
        font-size: 1.1rem;
    }

    .token-modal button {
        font-size: 1.1rem;
    }
}

@media (max-width: var(--breakpoint-mobile)) {
    .bubble__content {
        padding: 4px; /* Adjusted padding */
    }

    .bubble__logo {
        width: 45%;
        height: 45%;
    }

    .bubble__info-title {
        font-size: 1.1rem;
    }

    /* Removed the price styling
  .bubble__info-price {
    font-size: 0.95rem;
  }
  */

    .bubble__price-change {
        font-size: 0.85rem;
    }

    .token-modal h2 {
        font-size: 1.6rem;
    }

    .token-modal p {
        font-size: 1rem;
    }

    .token-modal button {
        font-size: 1rem;
        padding: 10px 20px;
    }
}
