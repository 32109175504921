.chart-container {
    background-color: #111111 !important;
  }/* Ensure no hardware acceleration affecting table */
table {
    will-change: auto !important;
  }
  
  /* Prevent text blur on hover */
  table, td, th {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  /* Remove unwanted scaling and transformations */
  tr:hover {
    transform: none !important;
    backface-visibility: hidden !important;
    perspective: none !important;
    translate: none !important;
  }
  
  /* Ensure fonts stay sharp */
  body {
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: antialiased;
    font-smooth: never;
  }