.holdersContainer {
    margin-top: 16px;
    padding: 16px;
    background-color: #141414;
    color: #fff;
    border-radius: 8px;
  }
  
  .holdersTitle {
    font-weight: bold;
    margin-bottom: 16px;
    color: #FFD700;
    font-size: 1.25rem;
    text-align: center;
  }
  
  .holdersChart {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  
  .holdersTable {
    width: 100%;
    background-color: #1a1a1a;
    border-collapse: collapse;
  }
  
  .holdersTable th,
  .holdersTable td {
    border: 1px solid #444;
    padding: 8px;
    text-align: left;
    color: #fff;
  }
  
  .holdersTable th {
    background-color: #141414;
    color: #FFD700;
  }
  
  .holdersToggleButton {
    margin-bottom: 16px;
    background-color: #FFD700;
    color: #141414;
    font-weight: bold;
  }