.workflow-container {
    background-color: #1c1c1c;
    color: #ffd700;
    padding: 20px;
    border: 2px solid #ffd700;
    border-radius: 10px;
    text-align: center;
}

.version-section {
    margin-bottom: 20px;
}

.status-section h2 {
    font-size: 24px;
    margin-top: 20px;
}

.status-light {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 10px;
}

.synced {
    background-color: #32cd32; /* Green light for synced */
    animation: sync-animation 1.5s infinite;
}

.not-synced {
    background-color: #ff4500; /* Red light for not synced */
}

@keyframes sync-animation {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.polling-indicator {
    font-size: 18px;
    margin-top: 15px;
    color: #32cd32;
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

.loading {
    font-size: 20px;
    color: #ffd700;
}

.error-message {
    font-size: 20px;
    color: #ff4500;
}
