/* src/games/poker/table.module.css */

.table {
    background-color: #444;
    padding: 40px;
    border-radius: 50%;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 500px;
    position: relative;
    margin-bottom: 40px;
}

/* Heading Styles */
.heading {
    color: #ffd700;
    font-size: 1.5em;
    margin-bottom: 10px;
}

/* Cards Container */
.cards {
    display: flex;
    gap: 10px;
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
}

/* Custom Card Styling */
.customCard {
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    width: 50px;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.1em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 5px;
    color: #000;
    transition: transform 0.2s ease;
    position: relative;
}

/* Card Hover Animation */
.customCard:hover {
    transform: translateY(-5px);
}

/* Card Value and Suit Styling */
.cardValue {
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    top: 5px;
    left: 5px;
}

.cardSuit {
    font-size: 20px;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

/* Red and Black Suit Colors */
.redSuit {
    color: #ff4444;
}

.blackSuit {
    color: #000;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .table {
        width: 100%;
        height: auto;
        padding: 20px;
    }

    .cards {
        flex-wrap: wrap;
        justify-content: center;
    }

    .customCard {
        width: 40px;
        height: 60px;
        font-size: 0.9em;
    }
}
