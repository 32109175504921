/* =========================
   Theme Variables
========================= */
:root {
    /* Color Palette */
    --primary-color: #515151; /* Dark muted gray */
    --secondary-color: #1a1a1a; /* Dark Gray */
    --background-color: #000000; /* Pure Black for the background */
    --primary-text-color: #e0e0e0; /* Light Gray for text */
    --highlight-color: #616161; /* Golden Yellow for key highlights */
    --secondary-text-color: #b0b0b0; /* Secondary Text */
    --hover-color: #2a2a2a; /* Dark Gray Hover Color */
    --border-color: #ffd700; /* Gold border for key elements */
    --buy-color: #00ff00; /* Green for Buy */
    --sell-color: #ff0000; /* Red for Sell */

    /* Font Sizes */
    --font-size-small: 0.7rem; /* 12px */
    --font-size-medium: 0.8rem; /* 14px */
    --font-size-large: 1.125rem; /* 18px */
    --font-size-base: 1rem; /* 16px */

    /* Spacing */
    --container-padding: 16px; /* Spacing inside containers */
    --section-gap: 20px; /* Gap between sections */
    --cell-padding: 8px; /* Padding inside table cells */
    --button-padding: 10px 18px; /* Button padding */

    /* Other */
    --border-radius: 8px;
    --transition-speed: 0.3s;
    --container-border: 1px solid var(--border-color); /* Gold border for containers */
    --hover-background-color: #4a4949; /* Updated hover background color */
}

/* =========================
   Global Box-Sizing
========================= */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* =========================
   Background Styling
========================= */
body {
    background-color: var(--background-color);
    color: var(--primary-text-color);
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: var(--font-size-base); /* Base font size */
    line-height: 1.6;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

/* =========================
   Updated Button Styling
========================= */
.button,
.feedback-button,
.view-toggle-btn,
.filter-icon,
.toolbar-button,
.wallet-tab,
.filter-button {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    padding: var(--button-padding);
    border: 2px solid var(--primary-color);
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color var(--transition-speed),
        color var(--transition-speed), transform var(--transition-speed),
        box-shadow var(--transition-speed), border-color var(--transition-speed);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.button:hover,
.feedback-button:hover,
.view-toggle-btn:hover,
.filter-icon:hover,
.toolbar-button:hover,
.wallet-tab:hover,
.filter-button:hover {
    background-color: var(--highlight-color);
    color: var(--secondary-color);
    transform: translateY(-2px) scale(1.05);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
    border-color: var(--highlight-color);
}

.button:active,
.feedback-button:active,
.view-toggle-btn:active,
.filter-icon:active,
.toolbar-button:active,
.wallet-tab:active,
.filter-button:active {
    transform: translateY(0) scale(1);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
}

.view-toggle-btn.active,
.toolbar-button.active,
.wallet-tab.active,
.filter-button.active {
    background-color: var(--secondary-color);
    color: var(--highlight-color);
    border-color: var(--highlight-color);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4);
}

/* Optional: Focus State for Accessibility */
.button:focus,
.feedback-button:focus,
.view-toggle-btn:focus,
.filter-icon:focus,
.toolbar-button:focus,
.wallet-tab:focus,
.filter-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.5); /* Gold glow */
}

/* =========================
   Vote Button Styling
========================= */
.vote-button {
    background-color: var(--highlight-color); /* Golden button */
    color: #000000;
    border: none;
    border-radius: var(--border-radius);
    padding: 12px 24px; /* Increased padding for a more prominent button */
    cursor: pointer;
    font-size: 1rem; /* Slightly larger font */
    transition: background-color var(--transition-speed),
        color var(--transition-speed), box-shadow var(--transition-speed),
        transform var(--transition-speed);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
    margin: 0.5rem 0; /* Add some margin around the button */
}

.vote-button:hover {
    background-color: var(--hover-color); /* Darker hover */
    color: var(--primary-text-color);
    transform: scale(1.05); /* Slightly larger on hover */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4); /* Deeper shadow on hover */
}

/* =========================
   Alph Banner
========================= */
.alph-banner {
    width: 100px; /* Adjust the size as needed */
    height: auto;
    margin-top: 10px; /* Space below the title */
    display: block;
    margin-left: auto;
    margin-right: auto; /* Center the image horizontally */
}

/* =========================
   Page Title Container
========================= */
.page-title {
    text-align: center; /* This centers the title horizontally */
    font-size: var(--font-size-large); /* Set the font size */
    color: var(--highlight-color); /* Use the golden yellow color */
    margin-top: 30px; /* Add some space at the top */
    margin-bottom: 20px; /* Add some space at the bottom */
    width: 100%; /* Ensure it takes up the full width */
}

/* =========================
   Modal Overlay
========================= */
.token-details-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* =========================
   Modal Styles
========================= */
.token-details-modal {
    position: relative; /* Relative to overlay */
    background-color: var(--secondary-color);
    padding: 1.875rem; /* 30px */
    border-radius: var(--border-radius);
    outline: none;
    width: 90%; /* Adjusted from 90% */
    max-width: 1000px; /* Reduced max-width from 1200px */
    max-height: 90vh;
    overflow-y: auto;
    color: var(--primary-text-color);
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.5); /* Enhanced shadow */
    transition: transform 0.3s ease, opacity 0.3s ease;
    border: var(--container-border); /* Gold border */
}

/* =========================
   Token Details Container
========================= */
.token-details-container {
    display: flex;
    flex-direction: row;
    gap: 1.25rem; /* 20px */
    background-color: var(--background-color);
    padding: 1rem; /* Adjusted padding */
    border-radius: var(--border-radius);
    color: var(--primary-text-color);
    justify-content: space-between;
    flex-wrap: nowrap; /* Prevent wrapping to maintain layout */
    width: 100%; /* Full width within modal */
    box-sizing: border-box;
    max-width: 1200px; /* Restrict the max width */
    margin: 0 auto; /* Center the content */
}

.token-details-toolbar {
    display: flex;
    justify-content: space-between; /* Align buttons on left, vote bar on right */
    align-items: center; /* Center items vertically */
    padding: 0.5rem 1rem;
    background-color: var(--secondary-color); /* Ensure consistent styling */
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}

.token-details-toolbar .toolbar-buttons {
    display: flex;
    gap: 0.5rem; /* Control spacing between the buttons */
    flex-wrap: nowrap; /* Ensure buttons stay in one row */
}

/* =========================
   Vote Result Bar
========================= */
/* Vote Result Bar Container */
.vote-result-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; /* Make it responsive */
    max-width: 300px; /* Maximum width for larger screens */
    height: 1.5rem;
    background-color: #2a2a2a; /* Dark background for contrast */
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    padding: 0 12px; /* Increased padding for better spacing */
    box-sizing: border-box;
    margin: 0.5rem 0;
    transition: box-shadow var(--transition-speed);
}

/* Vote Segments Container */
.vote-result-bar .vote-segments {
    display: flex;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    flex-grow: 1;
}

/* Individual Vote Segments */
.vote-result-bar .vote-segment {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: width var(--transition-speed) ease-in-out,
        background-color var(--transition-speed);
}

/* Bullish Votes Styling */
.vote-result-bar .vote-segment.bullish {
    background: linear-gradient(90deg, #00ff00, #32cd32); /* Green gradient */
}

/* Bearish Votes Styling */
.vote-result-bar .vote-segment.bearish {
    background: linear-gradient(90deg, #ff4d4d, #ff0000); /* Red gradient */
}

/* Vote Labels */
.vote-result-bar .vote-label {
    position: absolute;
    color: #ffffff;
    font-size: 0.7rem;
    font-weight: bold;
    pointer-events: none; /* Prevent interfering with hover */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Improve readability */
    white-space: nowrap;
}

/* Total Votes Text */
.vote-result-bar .total-votes {
    font-size: var(--font-size-small);
    color: var(--primary-text-color);
    margin-left: 12px; /* Increased margin for better spacing */
    white-space: nowrap;
}

/* No Votes Styling */
.vote-result-bar .no-votes {
    width: 100%;
    text-align: center;
    font-size: var(--font-size-small);
    color: var(--secondary-text-color);
}

/* Hover Effects */
.vote-result-bar:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

.vote-result-bar .vote-segment:hover {
    opacity: 0.9; /* Slight transparency on hover */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .vote-result-bar {
        max-width: 250px;
        height: 1.25rem;
    }

    .vote-result-bar .vote-label {
        font-size: 0.6rem;
    }

    .vote-result-bar .total-votes {
        font-size: 0.65rem;
    }
}

@media (max-width: 480px) {
    .vote-result-bar {
        max-width: 200px;
        height: 1rem;
        padding: 0 8px;
    }

    .vote-result-bar .vote-label {
        font-size: 0.5rem;
    }

    .vote-result-bar .total-votes {
        font-size: 0.6rem;
        margin-left: 8px;
    }
}

/* =========================
   Left and Right Sections
========================= */
.left-section,
.right-section {
    display: flex;
    flex-direction: column;
}

.left-section {
    flex: 2;
    padding-right: 1rem; /* Added padding for separation */
    min-width: 0; /* Prevent overflow */
}

.right-section {
    flex: 1;
    gap: 1rem; /* Space between Transaction table and Wallet */
    min-width: 0; /* Prevent overflow */
}

/* =========================
   Token Header
========================= */
.token-header {
    display: flex;
    align-items: center;
    gap: 1.5rem; /* Increase spacing between icons and text */
    padding: 0.5rem 1rem; /* Add padding to the container */
    color: var(--primary-text-color); /* Ensure consistent text color */
}

.token-header .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem; /* Set a fixed width */
    height: 1.5rem; /* Set a fixed height */
    border: 2px solid var(--highlight-color); /* Add a border around icons */
    border-radius: 50%; /* Make the icons circular */
    padding: 0.25rem; /* Add inner padding for icons */
}

/* =========================
   Share Icon
========================= */
.share-icon {
    cursor: pointer;
    font-size: 0.9375rem; /* 15px */
    color: var(--primary-color);
    margin-right: 1.25rem; /* 20px */
    transition: color var(--transition-speed);
}

.share-icon:hover {
    color: var(--highlight-color);
}

/* =========================
   Social Icons
========================= */
.social-icons {
    display: flex;
    align-items: center;
    gap: 1rem; /* Increase gap between icons */
    margin-left: auto; /* Push icons to the far right */
}

.social-icons a {
    color: var(--primary-text-color); /* Set icon color */
    transition: color var(--transition-speed); /* Smooth transition on hover */
}

.social-icons a:hover {
    color: var(--highlight-color); /* Highlight color on hover */
}

/* =========================
   Buy/Sell Text
========================= */
.price-cell {
    /* Default styling for the price cell */
    font-size: var(--font-size-medium);
}

.price-buy {
    color: var(--buy-color); /* Green for buy (positive change) */
}

.price-sell {
    color: var(--sell-color); /* Red for sell (negative change) */
}

/* =========================
   Token Info
========================= */
.token-info {
    display: flex;
    align-items: center;
    gap: 1rem; /* Adjust if necessary */
    color: var(--primary-text-color);
    margin-top: 0.625rem; /* 10px */
    padding: 0.5rem 1rem; /* Adjusted padding */
    border: 2px solid var(--highlight-color); /* Yellow border */
    border-radius: var(--border-radius); /* Rounded corners */
    white-space: nowrap; /* Prevents line wrapping */
    overflow: hidden; /* Hides overflow text */
}

.token-info div {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allows items to expand */
}

.token-info p {
    margin: 0; /* Adjusts margin to prevent additional spacing */
    font-size: var(--font-size-small); /* 14px */
}

/* =========================
   Enhanced Toolbar Styling
========================= */
/* Updated to use .buttons-toolbar instead of .toolbar-container */
.buttons-toolbar {
    width: 100%;
    display: flex;
    justify-content: center; /* Center the buttons */
    align-items: center;
    padding: 1rem;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    margin: 1rem auto;
    max-width: 1200px;
    flex-wrap: nowrap; /* Ensure it stays on one row */
    gap: 0.5rem; /* Reduce the space between buttons */
}

.buttons-toolbar .toolbar-buttons {
    display: flex;
    gap: 1.5rem; /* Increase this for more padding between buttons */
    justify-content: center;
    align-items: center;
}

.buttons-toolbar .toolbar-buttons .button,
.buttons-toolbar .toolbar-buttons .toolbar-button,
.buttons-toolbar .toolbar-buttons .filter-button,
.buttons-toolbar .toolbar-buttons .wallet-tab {
    /* Ensure consistent button styling within the toolbar */
    padding: var(--button-padding);
    margin: 0; /* Remove any default margins */
}

/* =========================
   Bar Container and Bars for Metrics
========================= */
/* Bar Container */
.bar-container {
    width: 100%;
    height: 0.5rem; /* 8px */
    background-color: var(--primary-color); /* Gold background */
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 0.5rem; /* 8px space between bars */
}

.bar {
    height: 100%;
    transition: width var(--transition-speed);
}

/* Specific Bar Types */
.bar.liquidity {
    background-color: #00ff00; /* Teal for Liquidity */
}

.bar.market-cap {
    background-color: #daa520; /* Goldenrod for Market Cap */
}

.bar.volume {
    background-color: #3498db; /* Blue for Volume */
}

/* =========================
   Holders Section
========================= */

/* Holders Pie Container */
.holders-pie-container {
    display: flex;
    align-items: center; /* Vertically center the pie chart */
    justify-content: center; /* Horizontally center the pie chart */
    gap: 1rem;
    width: 100%;
    height: 100%;
}

/* Adjust the holders-info-section */
.holders-info-section {
    display: flex;
    flex-direction: row;
    gap: 1rem; /* Add more space between the pie chart and table */
    background-color: var(--background-color);
    padding: 1rem; /* Add padding to create space */
    border-radius: var(--border-radius);
    color: var(--primary-text-color);
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    box-sizing: border-box;
}

/* Top Holders Table Container */
.top-holders-table-container {
    flex: 2;
    max-height: 300px; /* Height for scrollability */
    height: 100%; /* Ensure container respects parent height */
    overflow-y: auto; /* Enable vertical scrolling */
    background-color: var(--secondary-color);
    border-radius: var(--border-radius);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Holders Chart Container */
.holders-chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; /* Allow the pie chart to grow both vertically and horizontally */
    width: 100%; /* Allow it to take full width */
    height: auto; /* Remove fixed height and adjust dynamically */
    background-color: var(--background-color);
    border-radius: var(--border-radius);
    padding: 0;
    margin: 0;
}

.holders-chart-container svg {
    width: 100%; /* Allow the pie chart to grow proportionally */
    height: auto; /* Maintain the aspect ratio */
    max-width: 100%; /* Prevent it from growing larger than the container width */
    padding: 0;
    margin: 0;
}

.holders-chart-container text {
    font-size: 0.75rem;
}

/* =========================
   Top Holders Table
========================= */
.top-holders-table {
    width: 100%; /* Ensure table fills container */
    border-collapse: separate;
    border-spacing: 0;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius);
    overflow: hidden;
    font-size: var(--font-size-medium);
    color: var(--primary-text-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.top-holders-table th,
.top-holders-table td {
    padding: 4px 6px;
    white-space: nowrap;
}

.top-holders-table td {
    font-size: var(--font-size-small);
}

.holders-info-hidden {
    display: none;
}

.holders-info-visible {
    display: block;
}

/* =========================
   Percentage Bar in Top Holders Table
========================= */
/* Percentage Container */
.percentage-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Percentage Bar */
.percentage-container .percentage-bar {
    width: 100%;
    height: 4px; /* Small height to prevent increasing row height */
    background-color: #ffd700; /* Gold color for the bar */
    border-radius: 2px;
    margin-top: 2px; /* Small gap between percentage text and bar */
}

.percentage-container .percentage-bar:hover {
    background-color: var(--highlight-color); /* Gold hover color */
}

/* =========================
   Transaction Table Container
========================= */
.transaction-table-container {
    width: 100%;
    overflow: hidden; /* Prevent double scrollbars */
    margin-top: 20px;
    border: none; /* Remove second border */
    border-radius: var(--border-radius);
    background-color: var(--secondary-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
    max-height: 350px; /* Approximate height for 10 rows */
    overflow-y: auto; /* Make table scrollable */
    flex: 1; /* Allow it to grow */
}

/* =========================
   Transaction Table
========================= */
.transaction-table {
    width: 100%;
    border-collapse: separate; /* Changed to separate for better border control */
    border-spacing: 0;
    background-color: var(--secondary-color); /* Match overall theme */
    border-radius: var(--border-radius);
    overflow: hidden;
    font-size: var(--font-size-medium);
    color: var(--primary-text-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for table */
}

.transaction-table th,
.transaction-table td {
    padding: var(--cell-padding);
    text-align: left;
    border-bottom: 1px solid var(--primary-color); /* Gold bottom borders */
}

.transaction-table th {
    background-color: var(--secondary-color);
    color: var(--primary-color); /* Golden text for headers */
    position: sticky;
    top: 0; /* Stick to the top of the container */
    z-index: 3; /* Increased z-index to ensure headers are above other elements */
    font-weight: bold;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); /* Subtle shadow for separation */
}

.transaction-table tr {
    height: 30px; /* Consistent row height */
}

.transaction-table tr:hover {
    background-color: var(--hover-background-color);
}

.transaction-table tr.even-row {
    background-color: var(--background-color);
}

.transaction-table tr.odd-row {
    background-color: var(--secondary-color);
}

.transaction-table td {
    cursor: pointer;
    font-size: var(--font-size-medium); /* Updated to medium font size */
    position: relative; /* For positioning pulsing circles */
}

/* =========================
   Pulsing Circles in Transaction Table
========================= */
.pulsing-circle {
    width: 0.75rem; /* 12px */
    height: 0.75rem; /* 12px */
    border-radius: 50%;
    margin-right: 8px;
    animation: pulse 1.5s infinite;
}

.pulsing-circle.buy {
    background-color: var(--buy-color); /* Green for Buy */
}

.pulsing-circle.sell {
    background-color: var(--sell-color); /* Red for Sell */
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* =========================
   Wallet Container
========================= */
.wallet-container {
    flex: 1; /* Make the wallet container take equal space as the top holders */
    margin-top: 1rem; /* Space between Wallet and Transaction table */
    background-color: var(--secondary-color);
    padding: var(--container-padding);
    border-radius: var(--border-radius);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
    max-height: 450px; /* Match this with the top holders max height */
    overflow-y: auto; /* Keep scroll if content exceeds the height */
    box-sizing: border-box; /* Ensure padding is included in the height calculation */
}

/* Match the token name font size in the wallet component to the font size of the token holders */
.wallet-container .token-symbol {
    font-size: var(--font-size-small); /* Use the small font size variable */
    color: var(--primary-text-color); /* Ensure the text color matches */
    line-height: 1.6; /* Adjust if necessary to match the line height */
}

/* =========================
   Loading and Error Messages
========================= */
.loading-message,
.wallet-error-message,
.error-message,
.no-tokens-found {
    text-align: center;
    color: var(--primary-text-color);
    margin-bottom: 20px;
    font-size: 1.1em; /* Slightly larger for better visibility */
}

/* =========================
   Spinner Styles
========================= */
.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid var(--primary-color);
    width: 2.5rem; /* 40px */
    height: 2.5rem; /* 40px */
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* =========================
   Shared Table
========================= */
.shared-table {
    width: 100%;
    border-collapse: separate; /* Changed to separate for better border control */
    border-spacing: 0;
    background-color: var(
        --background-color
    ); /* Changed to match overall theme */
    border-radius: var(--border-radius);
    overflow: hidden;
    font-size: var(--font-size-medium);
    color: var(--primary-text-color);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow for table */
}

.shared-table th,
.shared-table td {
    padding: var(--cell-padding);
    text-align: left;
    border-bottom: 1px solid var(--primary-color); /* Gold bottom borders */
}

.shared-table th {
    background-color: var(--secondary-color);
    color: var(--highlight-color); /* Golden text for headers */
    position: sticky;
    top: 0; /* Stick to the top of the container */
    z-index: 3; /* Increased z-index to ensure headers are above other elements */
    font-weight: bold;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); /* Subtle shadow for separation */
}

.shared-table tr:hover,
.holders-table tr:hover,
.transaction-table tr:hover {
    background-color: var(--hover-background-color); /* Darker gray for hover */
    cursor: pointer; /* Adding pointer cursor for better feedback */
}

.shared-table tr.selected {
    background-color: var(--hover-color); /* Same background as hover */
    color: var(--primary-text-color); /* Ensure text color is consistent */
    font-weight: bold; /* Make text bold */
    position: relative; /* Position relative for pseudo-element */
    transition: none;
}

.shared-table tr.selected::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: var(--highlight-color); /* Gold border */
    width: 100%;
}

.shared-table td:first-child,
.balances-table td:first-child {
    color: var(--primary-color);
}

/* =========================
   Favorite Buttons in Tables
========================= */
.favorite-btn {
    background: none;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: var(--primary-color);
    margin-right: 10px;
    transition: color var(--transition-speed);
}

.favorite-btn.favorited {
    color: #ffd700;
}

.favorite-btn:not(.favorited):hover {
    color: #ff4500;
}

/* =========================
   Token Logos
========================= */
.token-logo {
    width: 1.25rem; /* 20px */
    height: 1.25rem; /* 20px */
    object-fit: contain;
    margin-right: 10px;
}

/* =========================
   Token Cell
========================= */
.token-cell {
    display: flex;
    align-items: center;
    gap: 8px; /* Spacing between elements */
}

/* =========================
   New Rank Styling
========================= */
.token-rank {
    font-size: var(--font-size-small);
    color: var(--highlight-color);
    margin-right: 8px; /* Space between rank and favorite star */
}

/* =========================
   Token Name
========================= */
.token-name {
    font-size: 0.7rem; /* Adjusted font size */
    color: #ffffff; /* White color for readability */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow if the text is too long */
    text-overflow: ellipsis; /* Add ellipsis (...) for overflowing text */
}

/* =========================
   Wallet and ANS Info
========================= */
.wallet-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--secondary-color);
    padding: var(--container-padding);
    color: var(--primary-text-color);
    border-radius: var(--border-radius);
    margin-bottom: 20px; /* Adjust spacing as needed */
    height: 3.125rem; /* 50px */
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Enhanced shadow */
}

.ans-avatar,
.doh-logo {
    width: 2.5rem; /* 40px */
    height: 2.5rem; /* 40px */
    border-radius: 50%;
    object-fit: cover;
}

.ans-info,
.wallet-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10px;
}

.ans-name,
.total-usd,
.wallet-address {
    margin: 0;
    text-align: left; /* Changed to left for consistency */
}

.ans-name {
    font-size: 0.9em; /* Reduced font size */
    color: var(--primary-color);
}

.total-usd {
    font-size: 0.8em; /* Reduced font size */
    color: #00ff00;
}

.wallet-address {
    font-size: 0.8em; /* Reduced font size */
    color: var(--primary-text-color); /* Adjusted color for better contrast */
}

/* =========================
   Alphpro Logo
========================= */
.alphpro-logo {
    width: 1.875rem; /* 30px */
    height: 1.875rem; /* 30px */
}

/* =========================
   Tabs
========================= */
.wallet-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 25px; /* Increased margin for better spacing */
}

.wallet-tab {
    background-color: var(--secondary-color);
    color: var(
        --primary-text-color
    ); /* Adjusted text color for better contrast */
    border: 1px solid var(--primary-color); /* Gold border */
    padding: 0.75rem 1rem; /* 12px 16px */
    margin-right: 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color var(--transition-speed),
        color var(--transition-speed), box-shadow var(--transition-speed);
    font-size: 0.9em;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.wallet-tab:hover {
    background-color: var(--hover-color);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Darker shadow on hover */
}

.wallet-tab.active {
    background-color: var(--hover-color);
    color: #000000;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for active state */
}

/* =========================
   Chart and Button Toolbar
========================= */
.chart-container {
    width: 100%;
    height: 250px; /* Adjust as needed */
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7);
    overflow: hidden;
    display: flex;
    justify-content: center; /* Center the chart */
    flex: 1; /* Allow it to grow */
}

/* =========================
   Search Bar
========================= */
.centered-search_bar {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 16px 0; /* Increased margin for better spacing */
}

.search-bar {
    width: 100%;
    max-width: 400px; /* Increased max-width for better usability */
}

.search-input {
    width: 100%;
    padding: 0.5rem; /* Increased padding for better input area */
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    font-size: var(--font-size-medium);
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
    transition: border-color var(--transition-speed),
        box-shadow var(--transition-speed);
}

.search-input:focus {
    outline: none;
    border-color: var(--highlight-color);
    box-shadow: 0 0 5px var(--highlight-color);
}

.search-input::placeholder {
    color: var(--secondary-text-color);
}

/* =========================
   Filter Container and Elements
========================= */
.filter-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem; /* Adjust spacing as needed */
}

.filter-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid var(--primary-color);
    border-radius: var(--border-radius);
    font-size: var(--font-size-medium);
    background-color: var(--secondary-color);
    color: var(--primary-text-color);
    transition: border-color var(--transition-speed),
        box-shadow var(--transition-speed);
}

.filter-input:focus {
    outline: none;
    border-color: var(--highlight-color);
    box-shadow: 0 0 5px var(--highlight-color);
}

.filter-input::placeholder {
    color: var(--secondary-text-color);
}

.filter-button {
    padding: 0.5rem 1rem;
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    border: none;
    border-radius: var(--border-radius);
    cursor: pointer;
    font-size: var(--font-size-medium);
    transition: background-color var(--transition-speed),
        transform var(--transition-speed);
}

.filter-button:hover {
    background-color: var(--highlight-color);
    transform: scale(1.05);
}

/* =========================
   Instant Feedback
========================= */
.instant-feedback {
    padding: 0.75rem 1rem;
    background-color: var(--hover-color);
    color: var(--primary-text-color);
    border-radius: var(--border-radius);
    margin-bottom: 1rem; /* Adjust spacing as needed */
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Subtle shadow */
}

.instant-feedback.success {
    background-color: #4caf50; /* Green background for success */
}

.instant-feedback.error {
    background-color: #f44336; /* Red background for error */
}

.instant-feedback .feedback-message {
    flex: 1;
    font-size: var(--font-size-medium);
}

.instant-feedback .close-feedback {
    background: none;
    border: none;
    color: var(--primary-text-color);
    font-size: 1.2rem;
    cursor: pointer;
    margin-left: 1rem;
}

/* =========================
   Token Details and Metrics
========================= */
.token-details-and-metrics {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* Space between TokenDetails and LiquidityMetrics */
    background-color: var(--secondary-color);
    padding: 1rem;
    border-radius: var(--border-radius);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Optional shadow */
}

/* =========================
   Metrics Container
========================= */
.metrics-container {
    display: flex;
    flex-direction: row; /* Align items horizontally */
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    gap: 1rem; /* Space between items */
    margin-top: 1rem;
}

/* Metric Item */
.metric-item {
    flex: 1 1 calc(33.333% - 1rem); /* Three items per row */
    min-width: 220px; /* Adjusted minimum width */
    background-color: var(--secondary-color);
    padding: 1rem;
    border-radius: var(--border-radius);
    box-sizing: border-box;
}

/* Metric Item Title */
.metric-item h4 {
    font-size: var(--font-size-medium);
    color: var(--primary-text-color);
    margin: 0 0 0.5rem 0;
}

/* Metric Item Value */
.metric-item p {
    font-size: var(--font-size-base);
    margin: 0 0 0.5rem 0;
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Bar Container */
.bar-container {
    width: 100%;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 5px;
    overflow: hidden;
}

.bar {
    height: 100%;
    border-radius: 5px;
    transition: width 0.3s ease-in-out;
}

/* Specific Bar Colors */
.bar.market-cap {
    background-color: #ffd700; /* Gold */
}

.bar.liquidity {
    background-color: #1abc9c; /* Teal */
}

.bar.volume {
    background-color: #3498db; /* Blue */
}

/* Info Icon */
.info-icon {
    margin-left: 0.5rem;
    font-size: 0.8em;
    cursor: pointer;
    color: var(--highlight-color);
}

/* =========================
   Responsive Adjustments
========================= */
@media (max-width: 768px) {
    .metric-item {
        flex: 1 1 calc(50% - 1rem); /* Two items per row */
    }

    /* Adjust the toolbar for smaller screens */
    .buttons-toolbar {
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
        padding: 1rem;
    }

    .chain-dropdown-container {
        flex: none;
        width: 100%;
    }

    .chain-dropdown {
        width: 100%;
    }

    /* Adjust Token Details Container */
    .token-details-container {
        flex-direction: column;
    }

    /* Adjust Metrics Container for Mobile */
    .metrics-container {
        flex-direction: column;
        gap: 10px;
    }

    .metric-item {
        flex: 1 1 100%; /* One item per row */
    }

    /* Adjust holders-info-section for tablets */
    .holders-info-section {
        flex-direction: column;
    }

    /* Adjust holders-pie-container for tablets */
    .holders-pie-container {
        flex-direction: row; /* Keep side by side on tablets */
        align-items: flex-start;
    }

    .holders-chart-container {
        width: 300px; /* Fixed width for the chart */
        height: 300px; /* Fixed height for the chart */
        padding: 10px; /* Add padding if necessary */
        background-color: var(
            --background-color
        ); /* Changed to match the dark theme */
        border-radius: var(--border-radius); /* Rounded corners for styling */
    }

    /* Adjust Top Holders Table Container for Smaller Screens */
    .top-holders-table-container {
        max-height: 480px; /* Adjusted height for smaller screens */
    }

    /* Vote Result Bar Responsive Adjustments */
    .vote-result-bar {
        height: 1rem; /* Further reduced height for mobile */
    }

    .total-votes {
        font-size: 0.6rem; /* Smaller font size */
    }
}

@media (max-width: 480px) {
    .metric-item {
        flex: 1 1 100%; /* One item per row */
    }

    .buttons-toolbar {
        gap: 8px; /* Further increase gap if needed */
        margin-bottom: 15px;
        max-width: 100%; /* Ensure full width on mobile */
    }

    .button,
    .feedback-button,
    .view-toggle-btn,
    .filter-icon {
        padding: 10px 16px;
        font-size: 0.9rem;
    }

    .wallet-header {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
    }

    .ans-info,
    .wallet-info {
        margin-left: 0;
        margin-top: 10px;
    }

    .wallet-tabs {
        flex-wrap: wrap;
    }

    .wallet-tab {
        margin-right: 0;
        margin-bottom: 10px;
    }

    /* Further Adjustments for Mobile Token Details Layout */
    .token-details-container {
        flex-direction: column;
    }

    .chart-container {
        order: 1; /* Chart first */
        width: 100%;
        margin-bottom: 15px;
        height: 290px; /* Further reduced for mobile */
    }

    .transaction-table-container {
        order: 2; /* Transaction table second */
        width: 100%;
        margin-bottom: 15px;
        background-color: var(
            --background-color
        ); /* Black background for main token table */
    }

    .token-info {
        order: 3; /* Token info third */
        width: 100%;
        margin-bottom: 15px;
    }

    .holders-pie-container {
        order: 4; /* Top holders fourth */
        width: 100%;
        margin-bottom: 15px;
        flex-direction: column;
    }

    .wallet-header {
        order: 5; /* Wallet selected last */
        width: 100%;
        height: auto;
    }

    /* Adjust Metrics Container for Mobile */
    .metrics-container {
        flex-direction: column;
        gap: 10px;
    }

    .metric-item h4,
    .metric-item p {
        font-size: var(--font-size-small);
    }

    .percentage-container .percentage-bar {
        height: 2px; /* Even smaller on mobile */
    }

    .bar-container {
        height: 0.375rem; /* 6px */
    }

    .token-details-modal {
        max-width: 900px;
        max-height: 90vh; /* Limit the modal height */
        overflow-y: auto; /* Allow scrolling */
        overflow-x: hidden;
    }

    .holders-chart-container {
        height: 7.5rem; /* 120px */
        width: 7.5rem; /* 120px */
        padding: 0;
        background-color: var(--background-color); /* Ensure consistency */
    }

    /* Vote Result Bar Responsive Adjustments */
    .vote-result-bar {
        height: 0.8rem; /* Further reduced height for mobile */
        width: 100%;
    }

    .vote-result-bar .total-votes {
        font-size: 0.6rem; /* Smaller font size */
    }

    /* Adjust Top Holders Table Container for Mobile */
    .top-holders-table-container {
        max-height: 480px; /* Adjusted height for smaller screens */
    }
}

/* =========================
   Additional Styles for Holders and Pie Chart
========================= */
/* Ensure that the holders-info-section uses Flexbox */
/* Adjust the holders-info-section */
/* Holders Info Section */
.holders-info-section {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    background-color: var(--background-color);
    padding: 0.5rem;
    border-radius: var(--border-radius);
    color: var(--primary-text-color);
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    box-sizing: border-box;
}

/* Scrollable Top Holders Table Container */
.top-holders-table-container {
    flex: 2;
    max-height: 350px; /* Enough height for about 7 rows, based on row height */
    overflow-y: auto;
    background-color: var(--secondary-color);
    border-radius: var(--border-radius);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
}

/* Fixed Pie Chart Container */
.holders-chart-container {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    height: 180px;
    border-radius: var(--border-radius);
    background-color: var(--background-color);
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.holders-chart-container svg {
    width: 100%;
    height: auto;
    max-width: 100%;
    padding: 0;
    margin: 0;
}

.holders-chart-container text {
    font-size: 0.75rem;
}

/* Responsive adjustments for holders-pie-container */
@media (max-width: 768px) {
    .holders-info-section {
        flex-direction: column;
    }

    .holders-chart-container {
        width: 100%;
        height: 300px;
        background-color: var(--background-color); /* Ensure dark background */
    }
}

@media (max-width: 480px) {
    .holders-pie-container {
        flex-direction: column;
    }

    .holders-chart-container {
        width: 100%;
        height: 250px;
        background-color: var(--background-color); /* Ensure dark background */
    }
}

/* =========================
   react-select Dropdown Styling
========================= */
/* Control (Dropdown button appearance) */
.react-select__control {
    background-color: var(--background-color); /* Black background */
    border: 2px solid var(--primary-color); /* Dark muted gray border */
    border-radius: var(--border-radius); /* Same rounded corners */
    height: 40px; /* Same height as buttons */
    min-width: 150px;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Same shadow as buttons */
    transition: background-color var(--transition-speed),
        color var(--transition-speed), transform var(--transition-speed);
}

.react-select__control:hover {
    background-color: var(--highlight-color); /* Gold background on hover */
    border-color: var(--highlight-color); /* Gold border on hover */
    color: var(--secondary-color); /* Dark gray text on hover */
    transform: translateY(-2px) scale(1.05); /* Slight hover lift */
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.4); /* Enhanced shadow */
}

.react-select__value-container {
    padding-left: 8px;
}

.react-select__single-value {
    color: var(--primary-text-color); /* Light gray text */
    display: flex;
    align-items: center;
}

.react-select__single-value img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;
}

.react-select__menu {
    background-color: var(--background-color); /* Black dropdown background */
    color: var(--primary-text-color); /* Light gray text */
    border: 2px solid var(--primary-color); /* Dark gray border */
    border-radius: var(--border-radius);
    z-index: 9999; /* Ensure dropdown appears above other elements */
}

.react-select__option {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    background-color: transparent;
    color: var(--primary-text-color); /* Light gray text */
}

.react-select__option--is-focused {
    background-color: var(--hover-color); /* Dark gray on focus */
    color: var(--highlight-color); /* Gold text on focus */
}

.react-select__option--is-selected {
    background-color: var(--highlight-color); /* Gold background for selected */
    color: #000000; /* Black text for selected */
}

.react-select__dropdown-indicator {
    color: var(--primary-text-color); /* Light gray for dropdown arrow */
}

.react-select__dropdown-indicator:hover {
    color: var(--highlight-color); /* Gold arrow on hover */
}

.react-select__indicator-separator {
    display: none;
}

.react-select__placeholder {
    color: var(--primary-text-color); /* Light gray placeholder */
}

/* Optional Responsive Adjustments */
@media (max-width: 768px) {
    .react-select__control {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .react-select__control {
        width: 100%;
    }
}

/* =========================
   Wallet Icon Styles
========================= */
.wallet-icon {
    margin-left: 8px;
    color: #1e90ff; /* DodgerBlue color for the icon */
    width: 16px;
    height: 16px;
}

.wallet-icon-link {
    display: inline-flex;
    align-items: center;
    margin-left: 4px; /* Slight margin to separate from text */
}

.wallet-icon-link .wallet-icon {
    flex-shrink: 0;
}

/* =========================
   Percentage Bar Styling for Top Holders Table
========================= */
/* Percentage Container */
.percentage-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Percentage Bar */
.percentage-container .percentage-bar {
    width: 100%;
    height: 4px; /* Small height to prevent increasing row height */
    background-color: #ffd700; /* Gold color for the bar */
    border-radius: 2px;
    margin-top: 2px; /* Small gap between percentage text and bar */
}

.percentage-container .percentage-bar:hover {
    background-color: var(--highlight-color); /* Gold hover color */
}

/* =========================
   Adjust Table Row Alignment
========================= */
.shared-table td,
.top-holders-table td,
.transaction-table td {
    vertical-align: middle; /* Center align contents vertically */
}

/* =========================
   Selected Row Highlight
========================= */
.shared-table tr.selected,
.top-holders-table tr.selected,
.transaction-table tr.selected {
    background-color: var(--hover-color); /* Same background as hover */
    color: var(--primary-text-color); /* Ensure text color is consistent */
    font-weight: bold; /* Make text bold */
    position: relative; /* Position relative for pseudo-element */
}

.shared-table tr.selected::after,
.top-holders-table tr.selected::after,
.transaction-table tr.selected::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: var(--highlight-color); /* Gold border */
    width: 100%;
}

/* =========================
   Responsive Adjustments for Top Holders Table
========================= */
@media (max-width: 768px) {
    .holders-info-section {
        flex-direction: column;
    }

    .holders-chart-container {
        width: 100%;
        height: 300px;
        background-color: var(--background-color); /* Ensure dark background */
    }
}

@media (max-width: 480px) {
    .holders-pie-container {
        flex-direction: column;
    }

    .holders-chart-container {
        width: 100%;
        height: 250px;
        background-color: var(--background-color); /* Ensure dark background */
    }
}

/* Add a soft glow to the edges of top-gainers without filling the entire row */
.top-gainer {
    border: 1px solid rgba(255, 223, 0, 0.6);
    box-shadow: 0 0 4px rgba(255, 223, 0, 0.3);
    transition: box-shadow 0.3s ease, border-color 0.3s ease; /* Smooth transition */
}

@keyframes pulse-glow {
    0%,
    100% {
        box-shadow: 0 0 8px rgba(255, 223, 0, 0.3);
    }
    50% {
        box-shadow: 0 0 12px rgba(255, 223, 0, 0.6);
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px rgba(255, 215, 0, 0.7),
            0 0 20px rgba(255, 215, 0, 0.4);
    }
    to {
        box-shadow: 0 0 20px rgba(255, 215, 0, 1),
            0 0 30px rgba(255, 215, 0, 0.6);
    }
}

/* Hover Tooltip Styling */
/* Style adjustments for Volume and Liquidity Bars */
.volume-bar,
.liquidity-bar {
    height: 10px; /* Match the height of the market cap bar */
    background-color: var(
        --primary-color
    ); /* Use the same base color as market cap */
    border-radius: 5px; /* Match the border radius of market cap bar */
    overflow: hidden;
    transition: width var(--transition-speed);
}

/* Inner bar for Volume */
/* Inner bar for Volume */
.volume-main {
    height: 100%;
    background-color: #ffd700; /* Use gold color for main volume bar */
}

/* Inner bar for Liquidity */
.liquidity-main {
    height: 100%;
    background-color: #ffd700; /* Use gold color for main liquidity bar */
}

/* Inner bar for Volume (Elexium) */
.volume-ex {
    height: 100%;
    background-color: #3498db; /* Blue color for Elexium volume bar */
}

/* Inner bar for Liquidity (Elexium) */
.liquidity-ex {
    height: 100%;
    background-color: #3498db; /* Blue color for Elexium liquidity bar */
}

/* Tooltip Styling for Hover (Optional, if needed) */
.volume-bar:hover::after,
.liquidity-bar:hover::after {
    content: attr(title);
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 12px;
    pointer-events: none;
}
